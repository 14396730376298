/**
 * InputGameManual Component
 *
 **/

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import { useState, useEffect } from 'react';

// ライブラリ
import validation from '../libs/validation'

// スタイル
import "../style/base.css"

/**
 * InputGameManual
 *
 */
function InputGameManual(props){
  const optionsDefault = {
    id: 'manual',
    className: '',
    placeholder: '例：\n　✙ ............ 移動\n　スペース ..... 回転\n　ESC ......... メニュー\n　エンター ..... 決定'
  }
  const options = Object.assign(optionsDefault, props)
  const [className, setClassName] = useState(options.className)
  const [feedback, setFeedback] = useState(props.feedback)
  delete options['className']
  delete options['feedback']

  useEffect(()=>{
    setFeedback(props.feedback)
  }, [props.feedback])

  //-------------------------------
  // 入力値のリアルタイムチェック
  //-------------------------------
  const checkManual = (e)=>{
    const value = e.target.value
    let newClass = className.replace('form-invalid', '').replace('form-valid', '')
    let fb = true

    if( value === '' ){
      ;
    }
    else if( ! validation.validManual(value) ){
      newClass += ' form-invalid'
      fb = false
    }
    else{
      newClass += ' form-valid'
    }

    setClassName(newClass)
    setFeedback(fb)
  }

  return(
    <>
      <textarea {...options} className={className} onChange={checkManual}></textarea>
      <p className="invalid-feedback" style={{display:feedback? 'none':'block'}}>
        操作方法を正しく入力してください
      </p>
    </>
  );
}

export default InputGameManual;