/**
 * InputGameDescription Component
 *
 **/

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import { useState, useEffect } from 'react';

// ライブラリ
import validation from '../libs/validation'

// スタイル
import "../style/base.css"

/**
 * InputGameDescription
 *
 */
function InputGameDescription(props){
  const optionsDefault = {
    id: 'description',
    className: '',
    placeholder: '例：\nパンダをモチーフにしたゆるい雰囲気のアクションパズルゲームです。\n同じ色のパンダを3つ揃えると爆発します。全30ステージあります。めちゃめちゃがんばって作りました＞＜\n\n遊んだ感想をコメント欄に書き込んでいただけると励みになります！'
  }
  const options = Object.assign(optionsDefault, props)
  const [className, setClassName] = useState(options.className)
  const [feedback, setFeedback] = useState(props.feedback)
  delete options['className']
  delete options['feedback']

  useEffect(()=>{
    setFeedback(props.feedback)
  }, [props.feedback])

  //-------------------------------
  // 入力値のリアルタイムチェック
  //-------------------------------
  const checkDescription = (e)=>{
    const value = e.target.value
    let newClass = className.replace('form-invalid', '').replace('form-valid', '')
    let fb = true

    if( value === '' ){
      ;
    }
    else if( ! validation.validDescription(value) ){
      newClass += ' form-invalid'
      fb = false
    }
    else{
      newClass += ' form-valid'
    }

    setClassName(newClass)
    setFeedback(fb)
  }

  return(
    <>
      <textarea {...options} className={className} onChange={checkDescription}></textarea>
      <p className="invalid-feedback" style={{display:feedback? 'none':'block'}}>
        説明文を正しく入力してください
      </p>
    </>
  );
}

export default InputGameDescription;