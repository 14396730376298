/**
 * 言語選択
 *
 * @returns {undefined}
 */
function GLangSelector() {
  return (
    <>
    { /*
    <div className="header-lang w-full py-0 px-16px tab:hidden">
      <div className="header-lang_container container-box flex justify-end">
        <div className="langSelector flex">
            <svg id="ico-language" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <rect id="icon-bg1" width="18" height="18" fill="none"/>
                <path id="globe-asia" d="M7.875,8a7.875,7.875,0,1,0,7.875,7.875A7.875,7.875,0,0,0,7.875,8Zm-.36,7.628a.508.508,0,0,1-.436.247h-.01a.508.508,0,0,0-.359.149l-.18.18a.254.254,0,0,0,0,.359l.18.18a.508.508,0,0,1,.149.359v.3a.508.508,0,0,1-.508.508H6.157a.508.508,0,0,1-.454-.281L4.984,16.19a.254.254,0,0,0-.407-.066l-.618.618a.508.508,0,0,1-.359.149H1.613A6.339,6.339,0,0,1,9.832,9.836L8.239,11.06a.254.254,0,0,0,.027.439l.344.172a.508.508,0,0,1,.281.454V14.6a.254.254,0,0,1-.254.254h-.1a.254.254,0,0,1-.227-.14.141.141,0,0,0-.246-.01l-.552.919Zm5.441,3.5a.508.508,0,0,1-.149.359l-.3.3a.508.508,0,0,1-.359.149h-.481a.508.508,0,0,1-.359-.149l-.413-.413a.85.85,0,0,0-.807-.224l-.675.169a.5.5,0,0,1-.123.015H8.956a.508.508,0,0,1-.359-.149l-.378-.378a.254.254,0,0,1-.074-.18v-.324a.254.254,0,0,1,.16-.236l1.249-.5a.851.851,0,0,0,.178-.1l.753-.536a.253.253,0,0,1,.147-.047h.384a.254.254,0,0,1,.235.157l.17.408a.127.127,0,0,0,.117.078h.121a.127.127,0,0,0,.122-.091l.133-.459a.127.127,0,0,1,.122-.091h.192a.127.127,0,0,1,.127.127v.411a.254.254,0,0,0,.074.18l.378.378a.508.508,0,0,1,.149.359v.781Z" transform="translate(1.125 -6.875)" fill="#c8328c"/>
            </svg>
            <div className="langSelector_container flex flex-col">
                <button className="btn-language">
                    Language
                    <svg id="ico-toggle" xmlns="http://www.w3.org/2000/svg" width="17" height="15.3" viewBox="0 0 17 15.3">
                        <rect id="icon-bg2" width="15.1" height="15.1" fill="none"/>
                        <path id="ico-toggle-2" data-name="ico-toggle" d="M1588.608,15.694,1583.3,6.571h10.625Z" transform="translate(-1580.108 -3.024)" fill="#969696"/>
                    </svg>
                </button>
            </div>
        </div>
      </div>
    </div>
    <div className="langSelector-pulldown pointer-events-none flex fixed -top-full right-0 h-full w-full flex-col z-searchBox transition-all duration-300">
      <div className="langSelector-pulldown_container container-box w-full flex justify-end">
        <div className="langSelector-pulldown_box pointer-events-auto w-240px flex flex-col">
            <ul className="langSelector-pulldown_list text-14px font-semibold p-8px w-240px bg-PrimaryGrad2">
                <li className="active">
                    <a href="/" className="langSelector-pulldown_item h-40px w-full flex justify-start items-center p-8px rounded-tl-xl rounded-br-xl text-color_White transition-all duration-300 cursor-pointer hover:bg-color_PrimaryDark" data-lang="ja">
                        <i className="icon icon-check opacity-0 bg-color_SidebarText mr-16px"></i><span>日本語</span>
                    </a>
                </li>
                <li >
                    <a href="/" className="langSelector-pulldown_item h-10 w-full flex justify-start items-center p-8px rounded-tl-xl rounded-br-xl text-color_White transition-all duration-300 cursor-pointer hover:bg-color_PrimaryDark" data-lang="en">
                        <i className="icon icon-check opacity-0 bg-color_SidebarText mr-16px"></i><span>English</span>
                    </a>
                </li>
            </ul>
            <div className="langSelector-pulldown_bottom">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="240" height="72" viewBox="0 0 240 72">
                    <defs>
                    <linearGradient id="linear-gradient-lang" x1="1" y1="0.5" x2="0" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#bf3392"/>
                        <stop offset="1" stopColor="#d2268f"/>
                    </linearGradient>
                    </defs>
                    <path id="bg-lang" d="M320,1212H559.976v10.919c0,6.77-2.608,18.3-14,23.823-15.321,7.429-39.649-3.96-70,5.564-50.788,15.94-75.438,42.271-125.985,27.193-31.981-9.54-30-67.3-30-54.6Z" transform="translate(-319.976 -1212)" fill="url(#linear-gradient-lang)"/>
                </svg>
            </div>
        </div>
    </div>
  </div>
    */}
  </>
  );
}

export default GLangSelector;