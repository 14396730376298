/**
 * ゲームの基本情報を更新 /games/config/:id
 *
 */

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// コンポーネント
import InputGameName from './InputGameName';
import InputCategory from './InputCategory';
import InputGameManual from './InputGameManual';
import InputGameDescription from './InputGameDescription';
import InputPlaytime from './InputPlaytime';

// UI
import Loading from './ui/Loading';
import Alert from './ui/Alert';

// ライブラリ
import { $ } from "../libs/util";
import scrollTop from "../libs/scrollTop";
import validation from "../libs/validation";
import API from '../libs/api';

// スタイル
import "../style/base.css"
import "../style/games.css"
import 'animate.css';
import 'react-tabs/style/react-tabs.css';

//-----------------------------------------------------------------------------
// 定数
//-----------------------------------------------------------------------------

/**
 * GamesConfig component
 *
 */
function GamesConfig() {
  const params = useParams();   // {id:'xxxxxx'}
  const gameid = params.id;
  console.log(gameid);

  // 画面の状態
  const [dispmode, setDispMode] = useState('loading');

  // ゲーム情報
  const [game, setGame] = useState({});

  // エラーメッセージ管理
  const [gameNameFb, setGameNameFb] = useState(true)
  const [gamePlatformFb, setGamePlatformFb] = useState(true)
  const [gameCategoryFb, setGameCategoryFb] = useState(true)
  const [gameManualFb, setGameManualFb] = useState(true)
  const [gameDescFb, setGameDescFb] = useState(true)
  //const [commentFb, setCommentFb] = useState(true)

  // 初回データ取得
  useEffect(() => {
    (async () => {
      const response = await API(`/game/detail/${gameid}?rnd=${Math.ceil( Math.random() * 10000)}`);
      console.log(response)

      if ( ! response || ! response.head.status ){
        setDispMode('error');
        return;
      }
      setGame(response.data);
      setDispMode('edit');
    })();
  }, [gameid]);

  /**
   * 基本情報更新ボタン押下
   *
   */
  const ClickBasicRegistBtn = async ()=>{
    const data = {
           sessid: window.User.sessid,
           gameid: gameid,
            title: $("gamename").value,
         platform: ($("platform1").checked ? 1 : 0) + ($("platform2").checked ? 2 : 0),
         category: $("category").value,
           manual: $("manual").value,
      description: $("description").value,
         playtime: $("playtime").value,
    }

    //---------------------------
    // 確認
    //---------------------------
    if(! validationBasicForm(data) ){
      return;
    }
    if(! window.confirm('本当にこの内容で更新しますか？') ){
      return;
    }

    //---------------------------
    // 登録処理開始
    //---------------------------
    setFormMode("group-basic", true)
    await BasicRegist(data);
    setFormMode("group-basic", false)
  }

  /**
   * Validation実行 - 基本情報
   *
   * @returns {boolean}
   */
  const validationBasicForm = (data)=>{
    const errors = []

    //---------------------------
    // エラー表示をクリア
    //---------------------------
    setGameNameFb(true)
    setGamePlatformFb(true)
    setGameCategoryFb(true)
    setGameManualFb(true)
    setGameDescFb(true)

    //---------------------------
    // 各項目をチェック
    //---------------------------
    if( data.title === "" || ! validation.validGameName(data.title) ){
      setGameNameFb(false)
      errors.push(['group-gamename', $("gamename")])
    }
    if( data.platform === 0 ){
      setGamePlatformFb(false)
      errors.push(['group-platform', null])
    }
    if( data.category === "0" ){
      setGameCategoryFb(false)
      errors.push(['group-category', $("category1")])
    }
    if( data.manual !== "" && ! validation.validManual(data.manual) ){
      setGameManualFb(false)
      errors.push(['group-manual', $("manual")])
    }
    if( data.description !== "" && ! validation.validDescription(data.description) ){
      setGameDescFb(false)
      errors.push(['group-description', $("description")])
    }

    //---------------------------
    // エラー時
    //---------------------------
    if( errors.length > 0 ){
      if( errors[0][1] !== null ){
        errors[0][1].focus()
      }
      scrollTop(errors[0][0])
      return(false)
    }

    return(true)
  }

  /**
   * 基本情報を更新
   *
   */
  const BasicRegist = async (data)=>{
    const response = await API(`/creator/game/update/basic`, 'POST', data)
    console.log(response, data)

    if ( ! response || ! response.head.status ){
      alert('更新に失敗しました');
      return;
    }
    alert('正常に更新しました');
  }


  // 送信中に入力フォームを無効化/有効化する
  const setFormMode = (id, flag)=>{
    const items = $(id).querySelectorAll('input, select, textarea, button');
    for (let i = 0; i < items.length; i++) {
      if(items[i]["data-freeze"] === "1"){
        continue;
      }
      items[i].disabled = flag;
    }
  }

  //---------------------------------------
  // 初回データ取得中 loading
  //---------------------------------------
  if( dispmode === 'loading' ){
    return(
      <div id="games-config" className="bg">
        <GamesConfigHeader />
        <section className="bg-section">
          <Loading />
        </section>
      </div>
    )
  }
  //---------------------------------------
  // 初回データ取得失敗 error
  //---------------------------------------
  else if( dispmode === 'error' ){
    return(
      <div id="games-config" className="bg">
        <GamesConfigHeader />
        <Alert type="danger">
          <h3>ゲーム情報の取得に失敗しました</h3>
          <p>申し訳有りませんがしばらく経ってから再度お試しください</p>
        </Alert>
      </div>
    )
  }

  //---------------------------------------
  // 入力フォーム表示 edit
  //---------------------------------------
  const tabInnerStyle = {marginTop:'0px', borderRadius:'0', border:'1px solid gray', borderTop:'none'}
  return (
    <div id="games-config" className="bg">
      <GamesConfigHeader />

      <div style={{marginTop:'30px'}}>
      <Tabs>
        <TabList style={{marginBottom:'0px'}}>
          <Tab>基本情報</Tab>
          <Tab>ゲームファイル</Tab>
          <Tab>サムネイル画像</Tab>
          <Tab>コミュニティ設定</Tab>
          <Tab style={{backgroundColor:'hotpink'}}>デンジャーゾーン</Tab>
        </TabList>
        <TabPanel>
          <section id="group-basic" className="bg-section" style={tabInnerStyle}>
            <h2>基本情報</h2>
            <div id="group-gamename" className="form-section">
              <label className="form-label">ゲーム名 <span className="text-red text-bold">★</span></label>
              <InputGameName className="form-control" feedback={gameNameFb} defaultValue={game.title} />
              <p className="help-block">
                ※2〜64文字。<a href="https://www.j-platpat.inpit.go.jp/" className="normal" target="_blank" rel="noreferrer">商標</a>にお気をつけください。
              </p>
            </div>
            <div id="group-platform" className="form-section">
              <label className="form-label">対応機種 <span className="text-red text-bold">★</span></label>
              <label className="form-check-label"><input type="checkbox" id="platform1" className="form-check" value="1" defaultChecked={/^1|3$/.test(game.platform)} /> PC Web</label>
              <label className="form-check-label"><input type="checkbox" id="platform2" className="form-check" value="2" defaultChecked={/^2|3$/.test(game.platform)} /> スマートフォン Web</label>
              <p className="invalid-feedback" style={{display:gamePlatformFb? 'none':'block'}}>
                対応機種を選択してください。
              </p>
            </div>
            <div id="group-category" className="form-section">
              <label className="form-label">カテゴリ <span className="text-red text-bold">★</span></label>
              <InputCategory className="form-select" feedback={gameCategoryFb} selected={game.categoryId} />
            </div>
            <div id="group-gameid" className="form-section">
            <label className="form-label">ゲームID <small class="text-red">※変更できません</small></label>
            <input type="text" className="form-control" defaultValue={game.gid} disabled={true} data-freeze="1" />
            <p className="help-block">
              ※URLに利用されます。この項目は<span className="text-red">あとから変更できません。</span><br />
              ※3〜64文字。半角英数字とハイフン(-)、アンダースコア(_)が利用可能です。未入力の場合はランダムに決定されます。
            </p>
          </div>
          <div id="group-manual" className="form-section">
            <label className="form-label">操作方法</label>
            <InputGameManual className="form-control" feedback={gameManualFb} defaultValue={game.manual}/>
            <p className="help-block">
              ※256文字まで入力できます。
            </p>
          </div>
          <div id="group-description" className="form-section">
            <label className="form-label">説明</label>
            <InputGameDescription className="form-control" feedback={gameDescFb} defaultValue={game.description}/>
            <p className="help-block">
              ※2048文字まで入力できます。
            </p>
          </div>
          <div id="group-playtime" className="form-section">
            <label className="form-label">1回あたりのプレイ時間</label>
            <InputPlaytime className="form-select" selected={game.playtime}/>
            <p className="help-block">
              ※大まかな時間でOKです
            </p>
          </div>
          <Alert type="warning">
            <h3>ご注意ください</h3>
            <ul className="list-normal">
              <li>更新した情報が反映されるまで10分程度かかります</li>
              <li>もし誤った情報を登録してしまうと、タイミングによっては望まない情報が10分間表示され続けることになります</li>
            </ul>
          </Alert>
          <div className="form-section">
            <button type="button" className="btn btn-primary box-center" onClick={()=>{ClickBasicRegistBtn()}}>「基本情報」を更新する</button>
          </div>
          </section>
        </TabPanel>
        <TabPanel>
          <section id="group-gamefile" className="bg-section" style={tabInnerStyle}>
            <h2>ゲームファイルの変更</h2>
            <p>※開発中です</p>
          </section>
        </TabPanel>
        <TabPanel>
          <section id="group-thumbnail" className="bg-section" style={tabInnerStyle}>
            <h2>サムネイル画像</h2>
            <p>※開発中です</p>
          </section>
        </TabPanel>
        <TabPanel>
          <section id="group-comment" className="bg-section" style={tabInnerStyle}>
            <h2>コミュニティ設定</h2>
            <p>※開発中です</p>
            {/*
            <div className="form-section">
              <label className="form-label">コメントを書き込めるユーザー <span className="text-red text-bold">★</span></label>
              <label className="form-radio-label"><input type="radio" id="comment1" name="comment" className="form-radio" value="1" defaultChecked={game.commentmode===1} /> 誰でも投稿可能</label>
              <label className="form-radio-label"><input type="radio" id="comment2" name="comment" className="form-radio" value="2" defaultChecked={game.commentmode===2} /> スポンサーのみ投稿可能</label>
              <label className="form-radio-label"><input type="radio" id="comment3" name="comment" className="form-radio" value="3" defaultChecked={game.commentmode===3} /> コメント機能OFF</label>
              <p className="invalid-feedback" style={{display:commentFb? 'none':'block'}}>
                コメントの設定を行ってください。
              </p>
            </div>
            <div className="form-section">
              <button type="button" className="btn btn-primary box-center" onClick={()=>{}}>「コミュニティ設定」を更新する</button>
            </div>
            */}
          </section>
        </TabPanel>
        <TabPanel>
          <section id="group-danger" className="bg-section" style={{...tabInnerStyle, backgroundColor:'#FFF0F5'}}>
            <h2>デンジャーゾーン</h2>
            <p>※開発中です（ゲームの公開停止などが行なえます）</p>
          </section>
        </TabPanel>
      </Tabs>
      </div>
    </div>
  );
}

function GamesConfigHeader(){
  return(
    <>
      <h1>ゲームの設定</h1>
      <Link to="/games">
        <button className="btn btn-normal" type="button">もどる</button>
      </Link>
    </>
  )
}

export default GamesConfig;
