/**
 * index.js
 *
 * @author M.Katsube
 */

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import React from 'react';
import ReactDOM from 'react-dom';

// Components
import App from './components/App';

// ライブラリ
import session from './libs/session';

//-----------------------------------------------------------------------------
// グローバル変数
//-----------------------------------------------------------------------------
window.User = {};

//-----------------------------------------------------------------------------
// メイン処理
//-----------------------------------------------------------------------------
(async ()=>{
  //-------------------------------------
  // ログイン情報を取得
  //-------------------------------------
  // セッション情報を取得
  window.User = await session.get()

  // 未ログインならログインページへリダイレクト
  if( ! session.isAuthUser() ){
    session.goLoginPage()
    return null
  }

  //-------------------------------------
  // JSXをレンダリング
  //-------------------------------------
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
    ()=>{
      const event = new CustomEvent('apploaded');
      window.dispatchEvent(event);
    }
  );
})();


