/**
 * DropzoneArea component
 *
 * @author M.Katsube
 */

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import React, {useMemo, useCallback} from 'react';
import { useDropzone } from 'react-dropzone';
import {encode} from 'html-entities';

// アイコン
import iconZip from "../../icon/icon-file-archive.svg";
import iconImage from "../../icon/icon-file-image.svg";

//-----------------------------------------------------------------------------
// スタイル
//-----------------------------------------------------------------------------
import "../../style/anime.css"

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 0px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: 'gray',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '100px',
  fontSize: '1.5rem',
  cursor: 'pointer',
};

const activeStyle = {
  borderColor: 'yellow'
};

const acceptStyle = {
  borderColor: 'blue',
  color: 'white',
  backgroundColor: 'lightblue'
};

const rejectStyle = {
  borderColor: 'red',
  color: 'white',
  backgroundColor: 'lightpink'
};

const displayFileNameStyle = {
  fontSize: '1.2rem',
  color: 'black',
  margin: '10px 0px',
  padding: '10px',
  backgroundColor: 'aliceblue',
  borderColor: '5px',
  animation: 'SlideIn 0.6s',
}

/**
 * DropzoneArea
 *
 * @param {object} props { type: 'zip' or 'image',
 *                         id: 'input id',
 *                         style: CSS,
 *                         maxSize: 1024 * 1024 * 50,
 *                         set: (setter functions)
 *                        }
 */
function DropzoneArea(props){
  const defaultOptions = {
    type: 'zip',
    id: 'drpzn-' + Math.floor(Math.random() * 1000000),
    style: {},
    maxSize: 1024 * 1024 * 100, // 50M -> 100M
  };
  const options = {...defaultOptions, ...props};
  delete options['set'];

  const [ddfile, setDDfile] = React.useState("");
  const onDrop = useCallback((acceptedFiles) => {
    if(acceptedFiles.length === 0){
      alert('正しいファイルを選択してください')
      return
    }
    const file = acceptedFiles[0];
    props.set(file)

    const line = (
      <p style={displayFileNameStyle}>
        <img src={getIconImage(options.type)} alt="icon" width="20" height="20" style={{display:'inline', marginRight:'5px'}} />
        <span className='text-bold'>{encode(file.name)}</span> - <span className='text-muted text-small'>{file.size.toLocaleString("ja")}byte ({new Date(file.lastModified).toLocaleDateString('sv')})</span>
      </p>
    )
    setDDfile(line);
  }, [options.type, props]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: getMimeType(options.type),
    maxFiles:1,
    maxSize: options.maxSize,
    minSize:1,
    multiple: false,
    onDrop
  });

  const style = useMemo(() => ({
    ...{...baseStyle, ...options.style},      // baseStyleをoptions.styleで上書き
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
    options.style
  ]);

  return (
    <section className="drpz-container">
      <div {...getRootProps({style})}>
        <input {...getInputProps({id: options.id})} />
        {isDragActive && isDragAccept ? (
          <p>今です！指を離して！</p>
          ) : (
          <p>
            ファイルをドラッグ＆ドロップしてください
          </p>
        )}
      </div>
      <div>
        {ddfile}
      </div>
    </section>
  );
}


function getMimeType(type){
  switch(type){
    case "zip":
//    return "application/zip";
      return ".zip,application/zip,application/x-zip-compressed";
    case "image":
      return "image/jpeg,image/png";
    default:
      return "";
  }
}

function getIconImage(type){
  switch(type){
    case "zip":
      return iconZip;
    case "image":
      return iconImage;
    default:
      return "";
  }
}

export default DropzoneArea;