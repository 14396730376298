/**
 * Logout component
 *
 */

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import { useState } from 'react';

// コンポーネント
import Spinner from './ui/Spinner';

// ライブラリ
import session from '../libs/session';

// スタイル
import "../style/base.css"


/**
 * ログアウト
 *
 * @returns {JSX}
 */
function Logout(){
  const [isProcessing, setIsProcessing] = useState(false)

  // 再ログイン
  const onLogin = ()=>{
    window.location.href = 'https://anko.in/user/login?ret=' + encodeURIComponent(window.location.href.replace('/logout', ''))
  }

  // ログアウト
  const onLogout = async ()=>{
    setIsProcessing(true)
    session.clearCache()     // ローカルストレージをクリア
    await session.logout()   // ログアウト
    window.location.href = 'https://play.anko.in/creator'
  }

  if( ! session.isAuthUser() ){
    return(
      <div className="bg">
        <h1>ログアウト</h1>
        <section className="bg-section">
          <h3>ログインされていません</h3>
          <p>すでにログアウト済みか、ログインされていません</p>
          <button type="button" className="btn btn-normal" onClick={onLogin}>
            もう一度ログイン
          </button>
        </section>
      </div>
    )
  }

  return(
    <div className="bg">
      <h1>ログアウト</h1>
      <section className="bg-section">
        <h3>本当にログアウトしますか？</h3>
        <p>次のサービスからも同時にログアウトします</p>
        <ul className="list-normal">
          <li>anko.in</li>
          <li>play.anko.in</li>
          <li>creator.anko.in</li>
        </ul>
        <button type="button" className="btn btn-normal" onClick={ async ()=>{await onLogout()} } disabled={isProcessing}>
          {isProcessing ? <Spinner /> : null}
          ログアウトする
        </button>
      </section>
    </div>
  )
}

export default Logout;