/**
 * Sponsors component
 *
 */

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import { useState, useEffect } from 'react';

// パーツ
import Alert from './ui/Alert';
import Spinner from './ui/Spinner';
import Loading from './ui/Loading';
import Accordion from './ui/Accordion';

// ライブラリ
import { $ } from '../libs/util';
import scrollTop from "../libs/scrollTop";
import validation from '../libs/validation';
import API from '../libs/api';

// スタイル
import "../style/base.css";

//-----------------------------------------------------------------------------
// 定数
//-----------------------------------------------------------------------------
const MODE_CD ={
  START: 1,
  STOP: 2
}
const ERR_CD = {
  PLAN_NAME: 1,
  PLAN_PRICE: 2,
  PLAN_BENEFIT: 3,
  BANK_CD: 4,
  BANK_SITEN: 5,
  BANK_TYPE: 6,
  BANK_ACCOUNT: 7,
  BANK_MEIGI: 8,
  HONMYOU: 9,
  BUSINESSMEAIL: 10
}

const FORM_ITEMS = [
  'name', 'price', 'benefit', 'bankcd', 'banksiten', 'banktype', 'bankaccount', 'bankmeigi', 'honmyou', 'businessmail'
]


/**
 * スポンサー管理
 *
 * @returns {undefined}
 */
function Sponsors() {
  const [isLoaded, setIsLoaded] = useState(true); // default: false
  const [loadFb, setLoadFb] = useState(true); // default: false
  /*
  const [sponsorConfig, setSponsorConfig] = useState(false);
  const [mode, setMode] = useState(MODE_CD.STOP);
  const [validError, setValidError] = useState([]);
  const [registProgress, setRegistProgress] = useState(false);

  useEffect(() => {
    scrollTop();
    (async function fetchData() {
      const response = await API('/creator/sponsor/plan/config', 'POST', {sessid: window.User.sessid});
      const isSuccess = response !== false && response.head.status
      setLoadFb( isSuccess )
      setSponsorConfig(isSuccess?  response.data:null);
      setMode(isSuccess && response.data? response.data.status:MODE_CD.STOP);

      if( isSuccess && response.data ){
        FORM_ITEMS.forEach((key) => {
          $(key).value = isSuccess? response.data[key]:undefined;
        })
      }

      setIsLoaded(true);
    })();

    // <input type="number">のホイールを無効化
    document.querySelectorAll('input[type="number"]').forEach((elm)=>{
      elm.onwheel = (e) => {
        e.preventDefault();
      };
    });
  }, [ ]);

  const onModeClick = (value) =>{
    setMode(value)
  }
  */

  /**
   * 登録
   *
   */
  /*
  const Regist = async () =>{
    const disableForm = (flag) =>{
      setRegistProgress(flag)
      FORM_ITEMS.forEach((key)=>{
        flag?
            $(key).setAttribute('disabled', true)
              :
            $(key).removeAttribute('disabled');
      });
    }

    // ボタンとフォームを無効化
    disableForm(true)

    // 募集停止
    if( mode === MODE_CD.STOP ){
      const result = await API('/creator/sponsor/plan/status', 'POST', {sessid: window.User.sessid, status: MODE_CD.STOP});
      if( result !== false && result.head.status ){
        alert('正常に更新されました');
      }
      else{
        alert('登録中にエラーが発生しました。時間を置いて再度お試しください');
      }
    }
    // 登録・更新
    else {
      const params = {
        sessid: window.User.sessid,
        name: $('name').value,
        benefit: $('benefit').value,
        price: $('price').value,
        bankcd: $('bankcd').value,
        banksiten: $('banksiten').value,
        banktype: $('banktype').value,
        bankaccount: $('bankaccount').value,
        bankmeigi: $('bankmeigi').value,
        honmyou: $('honmyou').value,
        businessmail: $('businessmail').value,
        status: MODE_CD.START
      }

      // 入力内容をチェック
      if( ! ValidationForm(params) || ! window.confirm('本当にこの内容で登録しますか？') ){
        disableForm(false)
        return;
      }

      // サーバへリクエスト
      const response = await API('/creator/sponsor/plan/add', 'POST', params);
      if( response !== false || response.head.status ){
        alert('正常に登録されました');
      }
      else{
        alert('登録中にエラーが発生しました。時間を置いて再度お試しください');
      }
    }

    disableForm(false)
  }
  */

  /**
   * Validation
   *
   * @param {object} params
   * @returns {boolean}
   */
  /*
  const ValidationForm = (params) =>{
    const errors = [];
    if( ! validation.validPlanName(params.name) ){
      errors.push('group-name', ERR_CD.PLAN_NAME);
    }
    if( ! validation.validPlanPrice(params.price) ){
      errors.push('group-price', ERR_CD.PLAN_PRICE);
    }
    if( ! validation.validPlanBenefit(params.benefit) ){
      errors.push('group-benefit', ERR_CD.PLAN_BENEFIT);
    }
    if( ! validation.validPlanBankCD(params.bankcd) ){
      errors.push('group-bankcd', ERR_CD.BANK_CD);
    }
    if( ! validation.validPlanBankSiten(params.banksiten) ){
      errors.push('group-banksiten', ERR_CD.BANK_SITEN);
    }
    if( ! validation.validPlanBankMeigi(params.bankmeigi) ){
      errors.push('group-bankmeigi', ERR_CD.BANK_MEIGI);
    }
    if( ! validation.validPlanHonmyou(params.honmyou) ){
      errors.push('group-honmyou', ERR_CD.HONMYOU);
    }
    if( ! validation.validPlanBusinessMail(params.businessmail) ){
      errors.push('group-businessmail', ERR_CD.BUSINESSMEAIL);
    }

    setValidError(errors);
    if( errors.length >= 1 ){
      scrollTop(errors[0]);
    }
    return errors.length === 0;
  }
*/
  return (
    <div className='bg'>
      <h1>スポンサー</h1>
      {/*
      <div id="loading" style={{display:isLoaded?  'none':'block'}}>
          <Loading />
      </div>
      <div id="loading-error" style={{display:isLoaded && !loadFb ? 'block':'none'}}>
        <Alert type="danger">
          <h2>通信エラーが発生しました</h2>
          申し訳ありませんがしばらく経ってから再度お試しください。
        </Alert>
      </div>
      */}

      <div id="sponsor-content" style={{display:isLoaded && loadFb? 'block':'none'}}>
        <strong className="text-big text-red">※今後リリース予定です※</strong>
        <section id="sponsor-description">
          <p>
            あなたの創作活動を応援してくれる「<span className="text-bold">スポンサー</span>」を<ruby>募<rt>つの</rt></ruby>ることができます。<br />
            スポンサーに加入してくださった方への「<span className="text-bold">特典</span>」を設定することで、報酬を毎月受け取ることができます。
          </p>
          <p><img src="https://ankoin.host/assets/img/creator/bnr-creator_04.png" alt="Creator/Sponsor" /></p>
        </section>

{/*
        <Alert type="info">
          <h2>募集を開始するには？</h2>
          <p>次の条件をクリアするとスポンサー機能が開放されます。</p>
          <ul className="list-normal">
            <li>1本以上のゲーム公開</li>
            <li>1本以上のスポンサー向けノート公開</li>
          </ul>
        </Alert>
        <section id="sponsor-registers" className="bg-section">
          <h2>登録者数</h2>
          <div className="text-mega">
            ---人
          </div>
        </section>

        <form id="sponsor-config" className="bg-section">
          <h2>現在の設定</h2>
          <div id="group-mode" className="form-section">
            <label className="form-label">スポンサーを募集しますか？ <span className="text-red text-bold">★</span></label>
            <label className="form-radio-label">
              <input type="radio" id="mode1" name="mode" className="form-radio"
                      value={MODE_CD.START}
                      onChange={()=>{onModeClick(MODE_CD.START)}}
                      checked={ mode === MODE_CD.START } />
              &nbsp;募集する
            </label>
            <label className="form-radio-label">
              <input type="radio" id="mode2" name="mode" className="form-radio"
                      value={MODE_CD.STOP}
                      onChange={()=>{onModeClick(MODE_CD.STOP)}}
                      checked={ mode === MODE_CD.STOP } />
              &nbsp;しない（停止）
            </label>
          </div>

          <div id="sponsor-config-input" style={{display:mode === MODE_CD.START? 'block':'none'}}>
            <InputPlan data={sponsorConfig} error={validError} />
            <InputBank data={sponsorConfig} error={validError} />
            <InputHonnin data={sponsorConfig} error={validError} />
            <InputTokushou data={sponsorConfig} error={validError} />

            <Alert type="warning">
              <h3>「特典」内容は必ず実施してください</h3>
              <ul className="list-normal">
                <li>「特典」はスポンサーの方から報酬をいただくための対価になります。必ずご自身が実現できる内容を記載ください。</li>
                <li>「特典」に虚偽の内容の記載や一方的に反故にした場合、<span className="text-bold text-red">訴訟などの法的な問題に発展する可能性</span>があります。</li>
                <li>「特典」はあとから変更できますので、最初は負担の少ない物を設定してください。</li>
              </ul>
            </Alert>
          </div>
          <div className="form-section">
            <button type="button" className="btn btn-primary box-center" onClick={async ()=>{ await Regist()}} disabled={registProgress}>
              この内容で登録する
              {registProgress? (<Spinner />):null}
            </button>
          </div>
        </form>
  */}
      </div>
    </div>
  );
}

/**
 * プラン内容の入力
 *
 * @returns {JSX.Element}
 */
/*
function InputPlan(props){
  return(
    <Accordion title="プラン内容" active={true}>
      <ul className="list-normal">
        <li>スポンサーの募集を開始するために以下の項目をすべてご入力ください</li>
        <li>この情報は<span className="text-marker-red">公開</span>されます</li>
      </ul>
      <div id="group-name" className="form-section">
        <label className="form-label">プラン名 <span className="text-red text-bold">★</span></label>
        <input type="text" id="name" className="form-control" placeholder="例：豪華マシマシプラン" />
        <p className="invalid-feedback" style={{display:props.error.includes(ERR_CD.PLAN_NAME)?  'block':'none'}}>
          プラン名を正しく入力してください。
        </p>
        <p className="help-block">
          ※2〜64文字<br />
          ※<span className="text-red text-bold">編集は1ヶ月に1回まで</span>可能です（変更後の内容は加入中のスポンサーに通知されます）<br />
        </p>
      </div>
      <div id="group-price" className="form-section">
        <label className="form-label">月額料金 <span className="text-red text-bold">★</span></label>
        <input type="number" id="price" className="form-control no-spin" placeholder="例：300" />
        <p className="invalid-feedback" style={{display:props.error.includes(ERR_CD.PLAN_PRICE)?  'block':'none'}}>
          月額料金を正しく入力してください。
        </p>
        <p className="help-block">
          ※300〜10000円まで設定できます（金額は税抜価格です）<br />
          ※この内容は<span className='text-red text-bold'>後から変更できません</span><br />
          ※最初は300〜500円の設定がおすすめです。
        </p>
      </div>
      <div id="group-benefit" className="form-section">
        <label className="form-label">特典 <span className="text-red text-bold">★</span></label>
        <textarea type="text" id="benefit" className="form-control" placeholder={"例：\n・スポンサー限定ノートが閲覧できます\n・ゲーム内のスタッフロールにお名前(ニックネーム)を掲載します"} />
        <p className="invalid-feedback" style={{display:props.error.includes(ERR_CD.PLAN_BENEFIT)?  'block':'none'}}>
          特典を正しく入力してください。
        </p>
        <p className="help-block">
          ※16〜1024文字まで<br />
          ※<span className="text-red text-bold">編集は1ヶ月に1回まで</span>可能です（変更後の内容は加入中のスポンサーに通知されます）<br />
          ※HTMLやMarkdownは利用できません。<br />
        </p>
      </div>
    </Accordion>
  )
}
*/

/**
 * 銀行口座の情報の入力
 *
 * @returns {JSX.Element}
 */
/*
function InputBank(props){
  return (
    <Accordion title="銀行口座" active={true}>
      <ul className="list-normal">
        <li>報酬を受け取るための日本国内に本支店のある金融機関情報を入力してください。</li>
        <li className="text-bold text-red">ご本人名義の口座に限ります</li>
        <li>ゆうちょ銀行の方は<a href="https://www.jp-bank.japanpost.jp/kojin/sokin/furikomi/kouza/kj_sk_fm_kz_1.html" target="_blank" rel="noreferrer" className="normal">こちらから</a>支店コードなどをお調べください</li>
        <li>報酬は月末に5000円以上の残高がある場合、手数料を差し引いた金額が翌月末に振り込まれます。<br /><span className="text-muted">（1000円単位でAmazonギフト券との交換も可能です）</span></li>
        <li>この情報は<span className="text-marker-green">非公開</span>です</li>
      </ul>
      <div id="group-bankcd" className="form-section">
        <label className="form-label">金融機関コード <span className="text-red text-bold">★</span></label>
        <input type="number" id="bankcd" className="form-control no-spin" placeholder={"例：0005"} />
        <p className="invalid-feedback" style={{display:props.error.includes(ERR_CD.BANK_CD)?  'block':'none'}}>
          金融機関コードを正しく入力してください。
        </p>
      </div>
      <div id="group-banksiten" className="form-section">
        <label className="form-label">支店コード（店番） <span className="text-red text-bold">★</span></label>
        <input type="number" id="banksiten" className="form-control no-spin" placeholder={"例：001"} />
        <p className="invalid-feedback" style={{display:props.error.includes(ERR_CD.BANK_SITEN)?  'block':'none'}}>
          支店コードを正しく入力してください。
        </p>
      </div>
      <div id="group-banktype" className="form-section">
        <label className="form-label">口座種別 <span className="text-red text-bold">★</span></label>
        <select id="banktype" className="form-select">
          <option value="1">普通</option>
          <option value="2">当座</option>
        </select>
      </div>
      <div id="group-bankaccount" className="form-section">
        <label className="form-label">口座番号 <span className="text-red text-bold">★</span></label>
        <input type="number" id="bankaccount" className="form-control no-spin" placeholder={"例：0123456"} />
        <p className="invalid-feedback" style={{display:props.error.includes(ERR_CD.BANK_TYPE)?  'block':'none'}}>
          口座番号を正しく入力してください。
        </p>
      </div>
      <div id="group-bankmeigi" className="form-section">
        <label className="form-label">名義人（全角カナ） <span className="text-red text-bold">★</span></label>
        <input type="text" id="bankmeigi" className="form-control" placeholder={"例：サトウアンコ"} />
        <p className="invalid-feedback" style={{display:props.error.includes(ERR_CD.BANK_MEIGI)?  'block':'none'}}>
          名義人を正しく入力してください。
        </p>
      </div>
    </Accordion>
  )
}
*/

/**
 * 本人確認情報の入力
 *
 * @returns {JSX.Element}
 */
/*
function InputHonnin(props){
  return(
    <Accordion title="ご本人情報" active={true}>
      <ul className="list-normal">
        <li>公的身分証明書（健康保険証、運転免許証、パスポートなど）に記載のある情報を入力してください</li>
        <li>ご利用内容によっては追加でご本人確認をお願いする場合がございます</li>
        <li>この情報は<span className="text-marker-green">非公開</span>です</li>
      </ul>
      <div id="group-honmyou" className="form-section">
        <label className="form-label">ご本名 <span className="text-red text-bold">★</span></label>
        <input type="text" id="honmyou" className="form-control" placeholder={"例：佐藤あんこ"} />
        <p className="invalid-feedback" style={{display:props.error.includes(ERR_CD.HONMYOU)?  'block':'none'}}>
          本名を正しく入力してください。
        </p>
      </div>
    </Accordion>
  )
}
*/

/**
 * 特定商取引法関連の入力
 *
 * @returns {JSX.Element}
 */
/*
function InputTokushou(props){
  return(
    <Accordion title="特定商取引法に基づく表記" active={true}>
      <ul className="list-normal">
        <li>「特定商取引法」に従いスポンサー様の決済ページに次の内容を表示します。</li>
        <li>「特定商取引法」は販売を行う事業者と購入者間のトラブルを防止することを目的として定められた法律です。</li>
        <li>スポンサーを募集する場合、クリエイターは事業者となるため連絡先や購入方法などを公開する必要があります。</li>
        <li>この情報は<span className="text-marker-red">公開</span>されます</li>
      </ul>
      <div id="group-businessmail" className="form-section">
        <label className="form-label">事業者の連絡先 <span className="text-red text-bold">★</span></label>
        <input type="text" id="businessmail" className="form-control" placeholder="メールアドレス" />
        <p className="invalid-feedback" style={{display:props.error.includes(ERR_CD.BUSINESSMEAIL)?  'block':'none'}}>
          メールアドレスを正しく入力してください。
        </p>
        <p className="help-block">
          ※公開可能なメールアドレスを入力してください<br />
          ※迷惑メールが届く可能性がありますので専用のアドレスを用意されることをおすすめします。<br />
          ※特定商取引法の定めにより、スポンサー様から決済に関して詳細な連絡先の問い合わせがあった場合は開示する必要があります
        </p>
      </div>
      <div className="form-section">
        <label className="form-label">事業者名 </label>
        <input type="text" className="form-control" value={window.User.nickname} disabled={true}/>
        <p className="help-block">
          ※ニックネームを表示します<br />
          ※ニックネームを変更した場合は自動的に反映されます（反映までにタイムラグがあります）
        </p>
      </div>
      <div className="form-section">
        <label className="form-label">料金 </label>
        <input type="text" className="form-control" value={'各プランページに記載しております'} disabled={true} />
        <p className="help-block">
          ※固定の文言を表示します（文言の変更はできません）
        </p>
      </div>
      <div className="form-section">
        <label className="form-label">代金の支払方法 </label>
        <input type="text" className="form-control" value={'クレジットカードによる決済となります'} disabled={true} />
        <p className="help-block">
          ※固定の文言を表示します（文言の変更はできません）
        </p>
      </div>
      <div className="form-section">
        <label className="form-label">代金の支払時期 </label>
        <textarea className="form-control" value={'月額料金制とし、スポンサーになった日に当月分の料金が決済され、\n以降は毎月20日の間に該当月分の料金が自動的に決済されます。'} disabled={true} style={{height:'70px'}}/>
        <p className="help-block">
          ※固定の文言を表示します（文言の変更はできません）
        </p>
      </div>
      <div className="form-section">
        <label className="form-label">提供時期 </label>
        <textarea className="form-control" value={'お支払方法の手続きが完了し次第、すぐにご利用いただけます。'} disabled={true} style={{height:'70px'}} />
        <p className="help-block">
          ※固定の文言を表示します（文言の変更はできません）
        </p>
      </div>
      <div className="form-section">
        <label className="form-label">返品等についての特約に関する事項 </label>
        <textarea className="form-control" value={'サービスの性質上、返品、キャンセルはできません。\nまた、途中解約などによる返金、日割り計算などは行いません。'} disabled={true} style={{height:'70px'}} />
        <p className="help-block">
          ※固定の文言を表示します（文言の変更はできません）
        </p>
      </div>
    </Accordion>
  )
}
*/

export default Sponsors;