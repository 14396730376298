import GLangSelector from "./GLangSelector";

/**
 * グローバルヘッダ
 *
 * @returns {undefined}
 */
function GHeader(props) {
  return (
    <header id="header" className="relative left-0 top-0 w-full transition-all duration-200">
      <GLangSelector />
        <div className="header-main w-full h-60px px-16px py-0 tab:h-40px tab:p-0">
            <div className="header-main_container container-box h-full flex items-center justify-between relative">
                <div className="header-hamburger flex justify-center items-center h-full tab:pr-8px tab:mr-8px tab:flex-1 tab:justify-start">
                    <button className="hamburger-btn my-0 mx-24px transition-all duration-300 cursor-pointer flex justify-center items-center h-full transform tab:mx-12px hover:rotate-360">
                        <svg id="ico-menu" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                        <rect id="icon-bg3" width="28" height="28" fill="none"/>
                        <path id="bars" d="M1,64.5H27a1,1,0,0,0,1-1V61a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v2.5A1,1,0,0,0,1,64.5Zm0,10H27a1,1,0,0,0,1-1V71a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v2.5A1,1,0,0,0,1,74.5Zm0,10H27a1,1,0,0,0,1-1V81a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v2.5A1,1,0,0,0,1,84.5Z" transform="translate(0 -58.25)" fill="#c8328c"/>
                        </svg>
                    </button>
                </div>
                <div className="header-main_start flex items-center relative w-full tab:w-140px tab:flex-shrink">
                    <a href="/" className="header-logo flex-shrink-0">
                        <figure className="flex justify-center items-center w-auto max-w-270px h-60px tab:w-140px tab:h-auto">
                            <img className="w-full h-full object-contain" src="https://ankoin.host/assets/img/common/logo-creator.svg" alt="anko.in Creator" />
                        </figure>
                    </a>
                    {/*
                    <form className="header-search relative w-full max-w-320px h-40px ml-16px tab:hidden">
                        <input type="search" className="header-search_input h-full w-full border-color_Gray border rounded px-4 placeholder-text" autoComplete="off" placeholder="Search" />
                        <i className="icon icon-search absolute right-16px top-10px bg-color_Gray"></i>
                    </form>
                    */}
                </div>
                <div className="header-main_end flex items-center relative ml-16px space-x-16px pr-8px tab:flex-1 tab:justify-end tab:ml-8px tab:space-x-8px">
                  <GheaderLogin />
                </div>
            </div>

        </div>{/*<!-- /.header-main --> */}

        <div className="search-sp flex fixed text-color_SidebarText -top-full right-0 w-full flex-col transition-all duration-300 z-searchBox">
            <div className="search-sp_head flex justify-between items-center w-full bg-color_BaseWhite">
                <figure className="flex justify-center items-center w-full h-full">
                    <img src="https://ankoin.host/assets/img/common/logo-creator.svg" alt="anko.in Creator" className="w-140px h-auto object-contain ml-35px" />
                </figure>
                <a href="/" className="search-sp_btn mx-12px my-0 transition-all duration-300 cursor-pointer flex justify-center items-center h-full flex-shrink-0">
                    <i className="icon icon-close bg-color_Primary"></i>
                </a>
            </div>
            <form className="search-sp_box w-full bg-color_BaseWhite relative py-16px px-2">
                <input type="search" className="header-search_input h-40px w-full border-solid border-color_Gray rounded py-0 px-4 placeholder-text" autoComplete="off" placeholder="Search" />
                <i className="icon icon-search absolute right-6 top-26px bg-color_Gray"></i>
            </form>
            <div className="search-sp_bottom w-full">
                <svg className="w-full h-auto" xmlns="http://www.w3.org/2000/svg" width="375" height="55.5" viewBox="0 0 375 55.5">
                    <path id="bg-search" d="M373.949,274H-1.013v6.921c0,6.745,3.649,17.865,21.873,21.755,40.168,8.573,64.9-12.642,109.368-3.855,79.4,15.689,117.871,41.123,196.851,26.1,49.97-9.5,46.87-54.681,46.87-42.024Z" transform="translate(1.013 -274.003)" fill="#f5f5f5"/>
                </svg>
            </div>
        </div>
        {/*<!-- /.search-sp -->*/}

    </header>
    );
}

function GheaderLogin(){
  return(
    <>
    <a href="https://help.anko.in/05-gameupload/" target="_blank" rel="noreferrer" className="btn btn-nav btn-developer justify-start tab:hidden">
      <i className="icon icon-code"></i>
      <span className="w-full justify-center">
          開発者向け情報
      </span>
    </a>
    {/*
    <a href="/games" className="btn btn-nav btn-publish justify-start tab:hidden">
      <i className="icon icon-publish"></i>
      <span className="w-full justify-center">
          ゲームを公開する
      </span>
    </a>
    */}
    { /*
    <div className="userNav-btn btn-user w-56px h-56px mr-8px cursor-pointer tab:hidden">
      <i className="icon icon-user w-full h-full bg-color_Dark"></i>
    </div>
    <div className="btn-language-sp hidden w-24px h-24px cursor-pointer tab:block">
        <i className="icon icon-language w-full h-full bg-color_Primary"></i>
    </div>
    <div className="btn-search hidden w-24px h-24px cursor-pointer tab:block">
        <i className="icon icon-search w-full h-full bg-color_Primary"></i>
    </div>
    <div className="header-userNav absolute top-50px right-0 z-gNav filter drop-shadow-lg transform -translate-y-100vh opacity-0">
      <div className="mr-18px ml-auto w-9 h-20px overflow-hidden block">
        <div className="h-6 w-6 bg-color_Primary rotate-45 transform origin-bottom-left"></div>
      </div>
      <div className="w-240px bg-color_Primary">
        <ul className="w-full flex flex-col p-16px text-color_White">
          <li className="username py-8px title-font text-color_White text-20px text-center">
            猫鍋スタジオ
          </li>
          <li><hr className="border-color_SidebarHr border-solid my-8px" /></li>
          <li>
            <a href="./sponsor/" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                <i className="icon icon-sponsor bg-color_SidebarText mr-16px"></i><span>スポンサー</span>
            </a>
          </li>
          <li>
              <a href="./follow/" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                  <i className="icon icon-follow bg-color_SidebarText mr-16px"></i><span>フォロー</span>
              </a>
          </li>
          <li>
              <a href="./thumbsup/" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                  <i className="icon icon-thumbsup bg-color_SidebarText mr-16px"></i><span>いいね</span>
              </a>
          </li>
          <li>
              <a href="./history/" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                  <i className="icon icon-history bg-color_SidebarText mr-16px"></i><span>プレイ履歴</span>
              </a>
          </li>
          <li>
              <a href="./savedata/" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                  <i className="icon icon-savedata bg-color_SidebarText mr-16px"></i><span>セーブデータ</span>
              </a>
          </li>
          <li>
              <a href="./sponsor/" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                  <i className="icon icon-setting bg-color_SidebarText mr-16px"></i><span>アカウント設定</span>
              </a>
          </li>
          <li>
              <a href="./signout/" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                  <i className="icon icon-signout bg-color_SidebarText mr-16px"></i><span>ログアウト</span>
              </a>
          </li>
        </ul>
      </div>
    </div>
    */ }
    </>
  );
}
export default GHeader;
