import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import ReactGA from "react-ga4";
import config from '../config';

/**
 * グローバルナビ
 *
 * @returns {undefined}
 */
function GNavi() {
  const user = window.User;
  const [navi, setNavi] = useState(getCategoryNum());

  const location = useLocation()
  useEffect(() => {
    const path = location.pathname;

    // Google Analytics
    ReactGA.initialize(config('GA4.TRACKING_ID'));
    ReactGA.send({hitType:"pageview", page:path});

    setNavi(getCategoryNum(path));
  }, [location])

  return (
    <div className="side-nav min-h-0 h-0 relative text-color_SidebarText mr-0 opacity-0 transition-all duration-300 transform -translate-x-240px w-0 overflow-hidden z-gNav tab:fixed tab:top-0 tab:left-0 tab:h-full tab:overflow-y-scroll">
      <div className="side-nav_head justify-between items-center w-240px bg-color_BaseWhite hidden tab:flex">
        <div className="hamburger-btn mx-24px transition-all duration-300 cursor-pointer flex justify-center items-center h-full flex-shrink-0 tab:mx-12px transform hover:rotate-360">
          <Link to="/"><i className="icon icon-close bg-color_Primary"></i></Link>
        </div>
        <figure className="flex justify-center items-center w-full h-full">
          <img src="https://ankoin.host/assets/img/common/logo-creator.svg" alt="anko.in Creator" className="w-140px h-auto object-contain -ml-36px" />
        </figure>
      </div>
      <div className="side-nav_container py-9px px-16px bg-PrimaryGrad2 w-240px">

        { /* <!-- ナビ --> */ }
        <div className="side-nav_studio w-full flex flex-col justify-center items-center py-16px px-0">
          <div className="studio-icon block w-128px h-128px bg-color_White rounded-1/2 tab:w-64px tab:h-64px">
            <AvatarImage />
          </div>
          <p className="studio-name pt-16px pb-0 px-0 text-18px leading-20px title-font text-color_White">{user.studio === null? "スタジオ名が未設定です":user.studio.name}</p>
        </div>
        <nav>
          <ul className="menu text-14px font-semibold">
            <li><hr className="border-color_SidebarHr border-solid my-8px" /></li>
            {/*
            <li className={(navi === 1)?  'active':null} onClick={()=>{setNavi(1)}}>
              <Link to="/" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                <i className="icon icon-home bg-color_SidebarText mr-16px"></i><span>ホーム</span>
              </Link>
            </li>
            <li><hr className="border-color_SidebarHr border-solid my-8px" /></li>
            */}
            <li className={(navi === 2)?  'active':null} onClick={()=>{setNavi(2)}}>
              <Link to="/games" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                <i className="icon icon-play bg-color_SidebarText mr-16px"></i><span>ゲーム</span>
              </Link>
            </li>
            {/*
            <li className={(navi === 3)?  'active':null} onClick={()=>{setNavi(3)}}>
              <Link to="/notes" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                <i className="icon icon-note bg-color_SidebarText mr-16px"></i><span>ノート</span>
              </Link>
            </li>
            */}
            <li className={(navi === 4)?  'active':null} onClick={()=>{setNavi(4)}}>
              <Link to="/sponsors" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                <i className="icon icon-sponsor bg-color_SidebarText mr-16px"></i><span>スポンサー</span>
              </Link>
            </li>
            {/*
            <li className={(navi === 5)?  'active':null} onClick={()=>{setNavi(5)}}>
              <Link to="/messages" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                <i className="icon icon-mail bg-color_SidebarText mr-16px"></i><span>メッセージ</span>
              </Link>
            </li>
            <li className={(navi === 6)?  'active':null} onClick={()=>{setNavi(6)}}>
              <Link to="/wallet" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                <i className="icon icon-wallet bg-color_SidebarText mr-16px"></i><span>おさいふ</span>
              </Link>
            </li>
            */}
            <li className={(navi === 7)?  'active':null} onClick={()=>{setNavi(7)}}>
              <Link to="/config" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                <i className="icon icon-setting bg-color_SidebarText mr-16px"></i><span>設定</span>
              </Link>
            </li>
            <li><hr className="border-color_SidebarHr border-solid my-8px" /></li>
            <li>
              <a href="https://help.anko.in/" target="_blank" rel="noreferrer" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                <i className="icon icon-help bg-color_SidebarText mr-16px"></i><span>ヘルプ</span>
              </a>
            </li>
            <li className={(navi === 8)?  'active':null} onClick={()=>{setNavi(8)}}>
              <Link to="/logout" className="h-40px w-full flex justify-start items-center p-8px rounded-tl-10px rounded-br-10px hover:bg-color_PrimaryDark">
                <i className="icon icon-signout bg-color_SidebarText mr-16px"></i><span>ログアウト</span>
              </Link>
            </li>
            <li><hr className="border-color_SidebarHr border-solid my-8px" /></li>
          </ul>
        </nav>
        { /* <!-- /ナビ --> */ }

        <div className="side-nav_bnr w-full flex flex-col items-center py-8px">
          {/*
          <a href="/" className="sp:hidden"><img src="https://ankoin.host/assets/img/bnr-assetstore_200-150.gif" alt="anko.in Asset Store" className="w-ad200px h-ad150px" /></a>
          <a href="/" className="mt-16px sp:hidden"><img src="https://ankoin.host/assets/img/GoogleAd_200-200.gif" alt="広告枠" className="w-ad200px h-ad200px" /></a>
          */}
        </div>
        <div className="side-nav_footer w-full py-14px px-4px text-10px leading-loose">
          <ul>
            <li><a href="https://twitter.com/ankoingames" target="_blank" rel="noreferrer">Twitter</a></li>
          </ul>
          <ul>
            <li><a href="https://help.anko.in/">お問い合わせ</a></li>
            <li><a href="https://anko.in/terms">利用規約</a></li>
            <li><a href="https://anko.in/privacy.html">プライバシーポリシー</a></li>
          </ul>
          <div className="copyright pt-14px">&copy; anko.in</div>
        </div>

      </div>
      <div className="side-nav_bottom w-240px">
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="240" height="72" viewBox="0 0 240 72">
          <defs>
            <linearGradient id="linear-gradient" x1="1" y1="0.5" x2="0" y2="0.5" gradientUnits="objectBoundingBox">
              <stop offset="0" stopColor="#bf3392"/>
              <stop offset="1" stopColor="#d2268f"/>
            </linearGradient>
          </defs>
          <path id="bg-menu" d="M320,1212H559.976v10.919c0,6.77-2.608,18.3-14,23.823-15.321,7.429-39.649-3.96-70,5.564-50.788,15.94-75.438,42.271-125.985,27.193-31.981-9.54-30-67.3-30-54.6Z" transform="translate(-319.976 -1212)" fill="url(#linear-gradient)"/>
        </svg>
      </div>
    </div>
  );
}

/**
 * アバター画像
 *
 * @returns {JSX.Element}
 */
function AvatarImage(){
  if(window.User.studio !== null && window.User.studio.icon !== null){
    return(
      <img src={`${config('HOST.IMAGE')}/${window.User.studio.icon}`} className="icon-creator" alt="Creator Icon" />
    );
  }
  else{
    return(
      <img src="https://ankoin.host/assets/ico/ico-user.svg" width="100%" height="100%" alt="Creator Icon" />
    );
  }
}

/**
 * カテゴリー番号を返却
 *
 * @param {string} pathname
 * @returns {number}
 */
function getCategoryNum(pathname=null){
  const path = pathname===null? window.location.pathname : pathname;
  if( path.match(/\/games/) ){
    return 2;
  }
  else if( path.match(/\/notes/) ){
    return 3;
  }
  else if( path.match(/\/sponsors/) ){
    return 4;
  }
  else if( path.match(/\/messages/) ){
    return 5;
  }
  else if( path.match(/\/wallet/) ){
    return 6;
  }
  else if( path.match(/\/config/) ){
    return 7;
  }
  else if( path.match(/\/logout/) ){
    return 8;
  }
  else{
    return 2;
  }
}

export default GNavi;