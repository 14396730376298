/**
 * ゲーム /games
 *
 */

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

// コンポーネント
import Alert from './ui/Alert';
import Loading from './ui/Loading';
import Card from './ui/Card';

// ライブラリ
import scrollTop from "../libs/scrollTop";
import API from '../libs/api';
import {cutString} from '../libs/util';
import config from '../config';

// アイコン
import iconGamepad from '../icon/icon-gamepad.svg';

// スタイル
import "../style/base.css"



/**
 * Games component
 *
 */
function Games() {
  const studioConfigured = (('studio' in window.User) && (window.User.studio !== null))
  const [games, setGames] = useState([]);
  const [gamelistLoaded, setGamelistLoaded] = useState(false);

  // 上部へスクロール
  scrollTop();

  /**
   * ゲーム一覧を取得する
   *
   */
  const getGames = async () => {
    const ls = await API('/creator/game/list', 'POST', {sessid: window.User.sessid});
    if(ls === false || ls.data === null ){
      setGames(false);
      return false;
    }
    setGames(ls.data);
  }

  useEffect(() => {
    (async function fetchData() {
      await getGames();
      setGamelistLoaded(true);
    })();
  }, []);

  return (
    <div className='bg'>
      <h1>ゲーム</h1>

      <div style={{display:studioConfigured? 'none':'block'}}>
        <NotStudioConfigured />
      </div>
      <div style={{display:studioConfigured? 'block':'none'}}>
        <form>
          <Link to="/games/add">
            <button className="btn btn-primary">ゲームを登録する</button>
          </Link>
        </form>

        <section className="bg-section">
          <div id="games-list-loading" style={{display:!gamelistLoaded?  'block':'none'}}>
            <Loading />
          </div>
          <div id="games-list" style={{display:gamelistLoaded && games === false?  'block':'none'}}>
            <Alert type="warning">
              <h2>ゲーム一覧を取得できませんでした。</h2>
              <p>しばらく経ってから再度お試しください。</p>
            </Alert>
          </div>
          <div id="games-list" style={{display:gamelistLoaded && games ?  'block':'none'}}>
            <GameList list={games} />
          </div>
        </section>
      </div>
    </div>
  );
}

function GameList(props){
  const styles = {
    display:'flex',
    flexWrap:'wrap',
    justifyContent:'left'
  }

  if( !('list' in props) || !(Array.isArray(props.list)) || props.list.length === 0 ){
    return(
      <Alert type="info">
        <h2>今はまだゲームが登録されていません。</h2>
        <p style={{margin:'20px 0px'}}>
          イカしたゲームのご登録をお待ちしてます！
        </p>
      </Alert>
    );
  }
  else{
    const list = props.list.map( game =>
      <Card key={game.gid}
            image={game.thumbnail===null? iconGamepad:`${config('HOST.IMAGE')}/${game.thumbnail}`}
            title={cutString(game.title, 48)}
            link={`${config('SERVICE.PLAY')}/game/${game.gid}`}>

        <form>
          <Link to={`/games/config/${game.gid}`}>
            <button type="button" className="btn btn-normal box-center">設定</button>
          </Link>
        </form>
      </Card>
    )

    return(
      <div className="card-list" style={styles}>
        {list}
      </div>
    )
  }
}

function NotStudioConfigured(){
  return(
    <Alert type="info">
      <h2>最初に「スタジオ名」「スタジオID」を設定してください。</h2>
      <p style={{margin:'20px 0px'}}>
        ゲームを登録するには最初に設定を行う必要があります。<br />
        お手数ですが以下のボタンから設定ページへ移動してください。
      </p>

      <form style={{margin:'10px 0px'}}>
        <Link to="/config">
          <button className="btn btn-normal">
            「設定」へ移動する
          </button>
        </Link>
      </form>
    </Alert>
  );
}

export default Games;
