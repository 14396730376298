import FormData from 'form-data'
import config from '../config'

const TYPE_CD = {
  USER_ICON:     '1',
  STUDIO_ICON:   '2',
  STUDIO_COVER:  '3',
  NOTE_ICON:     '4',
  NOTE_IMAGE:    '5',
  APP_THUMBNAIL: '6'
}

/**
 * ゲームファイルアップロード
 *
 * @param {string} file
 * @param {string} sessid
 * @param {string} [md5=null]
 * @returns {Promise<object>}
 */
async function gamezip(file, sessid, md5=null){
  if(file === null){
    return false
  }
  const url = `${config('API.UPLOAD')}/receive/app`
  const form = new FormData()
  form.append('appzip', file)
  form.append('sessid', sessid)
  form.append('md5', (md5===null)?  'nocheck':md5)

  try{
    const response = await window.fetch(url, {
      method: 'POST',
      body: form
    })
    return await response.json()
  }
  catch(e){
    console.error(e.message)
    return(false)
  }
}

/**
 * サムネ画像アップロード
 *
 * @param {object} file
 * @param {string} sessid
 * @param {string} uploadid
 * @returns {Promise<object>}
 */
async function thumbnail(file, sessid, uploadid){
  return await uploader(TYPE_CD.APP_THUMBNAIL, file, sessid, {uploadid})
}

/**
 * スタジオアイコンアップロード
 *
 * @param {object} file
 * @param {string} sessid
 * @returns {Promise<object>}
 */
async function studioIcon(file, sessid){
  return await uploader(TYPE_CD.STUDIO_ICON, file, sessid)
}

/**
 * スタジオカバーアップロード
 *
 * @param {object} file
 * @param {string} sessid
 * @returns {Promise<object>}
 */
async function studioCover(file, sessid){
  return await uploader(TYPE_CD.STUDIO_COVER, file, sessid)
}

/**
 * 汎用アップローダー
 *
 * @param {string} type
 * @param {object} file
 * @param {string} sessid
 * @param {object} [options=null]  {uploadid: string}
 * @returns {Promise<object>}
 */
async function uploader(type, file, sessid, options=null){
  if(file === null){
    return false
  }
  const url = `${config('API.REST')}/file/receive`
  const form = new FormData()
  form.append('type', type)
  form.append('data', file)
  form.append('sessid', sessid)
  form.append('mime', file.type)
  if( type === TYPE_CD.APP_THUMBNAIL ){
    form.append('uploadid', options.uploadid)
  }

  try{
    const response = await window.fetch(url, {
      method: 'POST',
      body: form
    })
    return await response.json()
  }
  catch(e){
    console.error(e.message)
    return(false)
  }
}

/**
 * アップロード情報を削除
 *
 * @param {string} sessid
 * @returns {Promise<object>}
 */
async function clearGameUpload(sessid){
  const url = `${config('API.REST')}/creator/game/add/clear`
  try{
    const response = await window.fetch(url, {
      method: 'POST',
      body: new URLSearchParams({
        sessid
      })
    })
    return await response.json()
  }
  catch(e){
    console.error(e.message)
    return(false)
  }
}

//------------------------------------------------------------
// export
//------------------------------------------------------------
const exp = {
  gamezip,
  thumbnail,
  studioIcon,
  studioCover,
  clearGameUpload
}
export default exp;