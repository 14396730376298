/**
 * ゲーム新規登録 /games/add
 *
 */

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// コンポーネント
import InputGameName from './InputGameName';
import InputGameID from './InputGameID';
import InputGameDescription from './InputGameDescription';
import InputGameManual from './InputGameManual';
//import InputGameTag from './InputGameTag';
import InputPlaytime from './InputPlaytime';
import InputCategory from './InputCategory';

// UI
import DropzoneArea from './ui/DropzoneArea';
import Spinner from './ui/Spinner';
import Alert from './ui/Alert';

// ライブラリ
import { $, $q, sleep } from "../libs/util";
import scrollTop from "../libs/scrollTop";
import validation from "../libs/validation";
import animateCSS from '../libs/animate';
import Upload from '../libs/upload';
import API from '../libs/api'

// スタイル
import "../style/base.css"
import "../style/games.css"
import 'animate.css';

// アイコン
import iconCheck from '../icon/icon-check.svg'
import iconNg from '../icon/icon-ng.svg'

//-----------------------------------------------------------------------------
// 定数
//-----------------------------------------------------------------------------
const PROGRESS_CD = {
  READY: 0,   // 準備中
  STEP1: 1,   // ゲームファイルアップロード
  STEP2: 2,   // ゲームファイルの内容を確認中
  STEP3: 3,   // サムネ画像アップロード
  STEP4: 4,   // 基本情報の登録
  STEP5: 5,   // 公開の準備中
  DONE: 9,    // 公開
}

/**
 * Games component
 *
 */
function GamesAdd() {
  // Fileオブジェクト入れ
  const [gameFile, setGameFile] = useState(null)
  const [gameThumbnail, setGameThumbnail] = useState(null)

  // エラーメッセージ管理
  const [gameFileFb, setGameFileFb] = useState(true)
  const [gameNameFb, setGameNameFb] = useState(true)
  const [gamePlatformFb, setGamePlatformFb] = useState(true)
  const [gameCategoryFb, setGameCategoryFb] = useState(true)
  const [gameIdFb, setGameIdFb] = useState(true)
  const [gameManualFb, setGameManualFb] = useState(true)
  const [gameDescFb, setGameDescFb] = useState(true)
  // const [gameTagFb, setGameTagFb] = useState(true)
  const [gameThumbnailFb, setGameThumbnailFb] = useState(true)
  const [commentFb, setCommentFb] = useState(true)

  // 登録状況管理
  const [progress, setProgress] = useState(PROGRESS_CD.READY)
  const [progressFb, setProgressFb] = useState(true)

  // 最初の1回だけ
  useEffect( ()=>{
    scrollTop();    // 上部へスクロール
  }, [ ])

  /**
   * 登録ボタン押下
   *
   */
  const ClickRegistBtn = async ()=>{
    //---------------------------
    // 確認
    //---------------------------
    if(! validationForm() ){
      return;
    }
    if(! window.confirm('本当にこの内容で登録しますか？') ){
      return;
    }

    //---------------------------
    // 登録処理開始
    //---------------------------
    // 登録
    setProgress(PROGRESS_CD.READY)
    setProgressFb(true)
    await windowToggle('progress')
    Regist()
  }


  /**
   * Validation実行
   *
   * @returns {boolean}
   */
  const validationForm = ()=>{
    const errors = [ ]
    const gamename  = $("gamename");
    const platform1 = $("platform1").checked;
    const platform2 = $("platform2").checked;
    const category  = $("category");
    const gameid    = $("gameid");
    const manual    = $("manual");
    const desc      = $("description");
    // const playtime  = $("playtime");
    // const [tag1, tag2, tag3] = [$("tag1"), $("tag2"), $("tag3")];
    const comment = $q('input[name="comment"]:checked');

    //---------------------------
    // エラー表示をクリア
    //---------------------------
    setGameFileFb(true)
    setGameNameFb(true)
    setGamePlatformFb(true)
    setGameCategoryFb(true)
    setGameIdFb(true)
    setGameManualFb(true)
    setGameDescFb(true)
    //setGameTagFb(true)
    setGameThumbnailFb(true)
    setCommentFb(true)

    //---------------------------
    // 各項目をチェック
    //---------------------------
    if( gameFile === null || ! validation.validAppFile(gameFile) ){
      setGameFileFb(false)
      errors.push(['group-gamefile', null])
    }
    if( gamename.value === "" || ! validation.validGameName(gamename.value) ){
      setGameNameFb(false)
      errors.push(['group-gamename', gamename])
    }
    if( ! platform1  &&  ! platform2 ){
      setGamePlatformFb(false)
      errors.push(['group-platform', null])
    }
    if( category.value === "0" ){
      setGameCategoryFb(false)
      errors.push(['group-category', category])
    }
    if( gameid.value !== "" && ! validation.validGameID(gameid.value) ){
      setGameIdFb(false)
      errors.push(['group-gameid', gameid])
    }
    if( manual.value !== "" && ! validation.validManual(manual.value) ){
      setGameManualFb(false)
      errors.push(['group-manual', manual])
    }
    if( desc.value !== "" && ! validation.validDescription(desc.value) ){
      setGameDescFb(false)
      errors.push(['group-description', desc])
    }
    /*
    [tag1, tag2, tag3].forEach((tag)=>{
      if( tag.value !== "" && ! validation.validTag(tag.value) ){
        setGameTagFb(false)
        errors.push(['group-tag', tag])
      }
    })
    */
    if( gameThumbnail !== null && ! validation.validThmubnail(gameThumbnail) ){
      setGameThumbnailFb(false)
      errors.push(['group-thumbnail', null])
    }
    if( comment.value === null ){
      setCommentFb(false)
      errors.push(['group-community', null])
    }

    //---------------------------
    // エラー時
    //---------------------------
    if( errors.length > 0 ){
      if( errors[0][1] !== null ){
        errors[0][1].focus()
      }
      scrollTop(errors[0][0])
      return(false)
    }

    return(true)
  }

  /**
   * 表示モードを切り替え
   *
   * @param {string} mode
   */
  const windowToggle = async (mode)=>{
    const GamesAddForm = $('games-add-form')
    const GamesAddProgress = $('games-add-progress')

    switch(mode){
      case 'input':
        // 登録状況を非表示
        GamesAddProgress.style.display = 'none'

        // 入力フォームを表示
        GamesAddForm.style.display = 'block'
        await animateCSS(GamesAddForm, 'bounceInDown')
        break;

      case 'progress':
        // 入力フォームを非表示
        scrollTop()
        await animateCSS(GamesAddForm, 'bounceOutUp')
        GamesAddForm.style.display = 'none'

        // 登録状況を表示開始
        GamesAddProgress.style.display = 'block'
        await animateCSS(GamesAddProgress, 'slideInDown')
        break;

      default:
        break;
    }
  }

  /**
   * ゲーム登録
   *
   */
  const Regist = async ()=>{
    let retry_count = 0;
    let uploadid = null;
    let thumbnail = null;

    //------------------------------------------
    // READY 過去のアップロード情報をクリア
    //------------------------------------------
    const ret0 = await Upload.clearGameUpload(window.User.sessid)
    if(! ret0 ){
      setProgressFb(false)
      return(false)
    }

    //------------------------------------------
    // STEP1. ゲームファイルアップロード
    //------------------------------------------
    setProgress(PROGRESS_CD.STEP1)
    const ret1 = await Upload.gamezip(gameFile, window.User.sessid)
    if(! ret1 ){
      setProgressFb(false)
      return(false)
    }

    //------------------------------------------
    // STEP2. ゲームファイルの内容確認中
    //------------------------------------------
    setProgress(PROGRESS_CD.STEP2)
    retry_count = 0
    do{
      const ret2 = await API('/creator/game/add/result', 'POST',{
        sessid: window.User.sessid
      })
      console.log('Regist - STEP2', retry_count, ret2)

      // 500番、処理時にエラーが発生した場合はエラー終了
      if( ret2 === false || ('data' in ret2 && 'result' in ret2 && ret2.data.result !== 1) ){
        setProgressFb(false)
        return(false)
      }
      // データが存在しない場合は10秒待機しリトライ
      else if( ! ret2.head.status ){
        ++retry_count
        await sleep(1000 * 10)
      }
      // データが存在すれば次のステップへ
      else if( ret2.data.result === 1 ){
        uploadid = ret2.data.uploadid
        break;
      }

      // リトライが15回を超えいている場合はエラー終了
      if( retry_count > 15 ){
        setProgressFb(false)
        return(false)
      }
    }
    while(true);

    //------------------------------------------
    // STEP3. サムネ画像のアップロード
    //------------------------------------------
    setProgress(PROGRESS_CD.STEP3)
    if( gameThumbnail !== null ){
      const ret3 = await Upload.thumbnail(gameThumbnail, window.User.sessid, uploadid)
      if( ret3 === false || ret3.head.status !== true ){
        setProgressFb(false)
        return(false)
      }
      thumbnail = ret3.data.path
    }

    //------------------------------------------
    // STEP4. 基本情報の登録
    //------------------------------------------
    setProgress(PROGRESS_CD.STEP4)
    const title = $("gamename").value
    const gameid = $("gameid").value
    const platform = ($("platform1").checked ? 1 : 0) + ($("platform2").checked ? 2 : 0)
    const category = $("category").value
    const manual = $("manual").value
    const description = $("description").value
    const playtime = $("playtime").value
    const commentmode = $q('input[name="comment"]:checked').value
    // const [tag1, tag2, tag3] = [$("tag1"), $("tag2"), $("tag3")];

    // パラメーターを作成
    const params = {
      sessid: window.User.sessid,
      uploadid,
      title,
      platform,
      category,
    };
    if(thumbnail !== null) params.thumbnail = thumbnail
    if(gameid !== "") params.gameid = gameid
    if(manual !== "") params.manual = manual
    if(description !== "") params.description = description
    if(playtime.match(/^(1|3|10|30|999)$/) ) params.playtime = playtime
    if(commentmode !== "") params.commentmode = commentmode
    // if(tag1.value !== "") params.tag1 = tag1.value
    // if(tag2.value !== "") params.tag2 = tag2.value
    // if(tag3.value !== "") params.tag3 = tag3.value

    // リクエスト送信
    const ret4 = await API('/creator/game/add', 'POST', params)
    if( ret4 === false || ret4.head.status !== true ){
      setProgressFb(false)
      return(false)
    }

    //------------------------------------------
    // STEP5. 公開の準備中
    //------------------------------------------
    setProgress(PROGRESS_CD.STEP5)

    //------------------------------------------
    // 公開完了
    //------------------------------------------
    setProgress(PROGRESS_CD.DONE)
  }

  //---------------------------
  // render
  //---------------------------
  return (
    <div id="games-add" className="bg">
      <h1>ゲームを新しく登録する</h1>
      <form id="games-add-form">
        <Link to="/games">
          <button className="btn btn-normal" type="button">もどる</button>
        </Link>

        <section id="group-gamefile" className="bg-section">
          <h2>1. ゲームファイル</h2>
          <ul className="list-normal">
            <li>ゲームの動作に必要なファイルを<span className="text-red text-bold">Zip形式で圧縮し</span>ドラッグ＆ドロップしてください。<span className='text-muted'>(HTML/CSS/JavaScript,画像や音声など)</span></li>
            <li>1ゲームにつき<span className="text-red text-bold">100Mbyteまで</span>アップロードできます<span className='text-muted'>（圧縮後の容量）</span></li>
            <li><span className="text-red text-bold">index.html</span>を一番上の階層に置いてください<span className='text-muted'>（最初に呼び出されます）</span></li>
            <li>詳細は<a href="https://help.anko.in/05-gameupload/" className="normal" target="_blank" rel="noreferrer">ドキュメント</a>をご覧ください。</li>
          </ul>
          <DropzoneArea id="gamefile" type="zip" set={setGameFile}/>
          <p className="invalid-feedback" style={{display:gameFileFb? 'none':'block'}}>
            ファイルを正しくセットしてください。
          </p>
        </section>

        <section className="bg-section">
          <h2>2. ゲームの基本情報を入力してください</h2>
          <ul className="list-normal">
            <li>これらの情報は公開後に変更することができます。</li>
            <li><span className="text-red">★</span>がついている項目は必ずご入力ください。</li>
          </ul>
          <div id="group-gamename" className="form-section">
            <label className="form-label">ゲーム名 <span className="text-red text-bold">★</span></label>
            <InputGameName className="form-control" feedback={gameNameFb} />
            <p className="help-block">
              ※2〜64文字。<a href="https://www.j-platpat.inpit.go.jp/" className="normal" target="_blank" rel="noreferrer">商標</a>にお気をつけください。
            </p>
          </div>
          <div id="group-platform" className="form-section">
            <label className="form-label">対応機種 <span className="text-red text-bold">★</span></label>
            <label className="form-check-label"><input type="checkbox" id="platform1" className="form-check" value="1" /> PC Web</label>
            <label className="form-check-label"><input type="checkbox" id="platform2" className="form-check" value="2" /> スマートフォン Web</label>
            <p className="invalid-feedback" style={{display:gamePlatformFb? 'none':'block'}}>
              対応機種を選択してください。
            </p>
          </div>
          <div id="group-category" className="form-section">
            <label className="form-label">カテゴリ <span className="text-red text-bold">★</span></label>
            <InputCategory className="form-select" feedback={gameCategoryFb}/>
          </div>
          <div id="group-gameid" className="form-section">
            <label className="form-label">ゲームID</label>
            <InputGameID  className="form-control" feedback={gameIdFb}/>
            <p className="help-block">
              ※URLに利用されます。この項目は<span className="text-red">あとから変更できません。</span><br />
              ※3〜64文字。半角英数字とハイフン(-)、アンダースコア(_)が利用可能です。未入力の場合はランダムに決定されます。
            </p>
          </div>
          <div id="group-manual" className="form-section">
            <label className="form-label">操作方法</label>
            <InputGameManual className="form-control" feedback={gameManualFb} />
            <p className="help-block">
              ※256文字まで入力できます。
            </p>
          </div>
          <div id="group-description" className="form-section">
            <label className="form-label">説明</label>
            <InputGameDescription className="form-control" feedback={gameDescFb} />
            <p className="help-block">
              ※2048文字まで入力できます。
            </p>
          </div>
          <div id="group-playtime" className="form-section">
            <label className="form-label">1回あたりのプレイ時間</label>
            <InputPlaytime className="form-select" />
            <p className="help-block">
              ※大まかな時間でOKです
            </p>
          </div>
          { /*
          <div id="group-tag" className="form-section">
            <label className="form-label">タグ</label>
            <InputGameTag id="tag1" className="form-control size-1-3" placeholder="例：落ちゲー"/>
            <InputGameTag id="tag2" className="form-control size-1-3" placeholder="例：パンダ"/>
            <InputGameTag id="tag3" className="form-control size-1-3" placeholder="例：体は闘争を求める"/>
            <p className="invalid-feedback" style={{display:gameTagFb? 'none':'block'}}>
              タグを正しく入力してください。
            </p>
            <p className="help-block">
              ※それぞれ2〜8文字まで入力できます
            </p>
          </div>
          */ }
        </section>

        <section id="group-thumbnail" className="bg-section">
          <h2>3. サムネイル画像</h2>
          <ul className="list-normal">
            <li>ゲームの内容がわかる画像をアップロードしてください。未登録の場合は汎用的な画像が表示されます。</li>
            <li><span className="text-red">3Mbyte以下のJPEG, PNG形式の画像</span>が利用できます。</li>
            <li>アスペクト比は<span className="text-red">16:9</span>でご制作ください <span className="text-muted">(1280x720pxがおすすめです)</span></li>
          </ul>
          <div className="form-section">
            <DropzoneArea id="thumbnail" type="image" maxSize={ 1024 * 1024 * 3} set={setGameThumbnail}/>
            <p className="invalid-feedback" style={{display:gameThumbnailFb? 'none':'block'}}>
              ファイルを正しくセットしてください。
            </p>
          </div>
        </section>

        <section id="group-community" className="bg-section">
          <h2>4. コミュニティ設定</h2>
          <ul className="list-normal">
            <li>コメント機能は将来的にリリース予定です。リリース時に今回の設定内容が利用されます。</li>
          </ul>
          <div className="form-section">
            <label className="form-label">コメントを書き込めるユーザー <span className="text-red text-bold">★</span></label>
            <label className="form-radio-label"><input type="radio" id="comment1" name="comment" className="form-radio" value="1" defaultChecked /> 誰でも投稿可能</label>
            <label className="form-radio-label"><input type="radio" id="comment2" name="comment" className="form-radio" value="2" /> スポンサーのみ投稿可能</label>
            <label className="form-radio-label"><input type="radio" id="comment3" name="comment" className="form-radio" value="3" /> コメント機能OFF</label>
            <p className="invalid-feedback" style={{display:commentFb? 'none':'block'}}>
              コメントの設定を行ってください。
            </p>
          </div>
        </section>

        <section className="bg-section">
          <h2>5. 公開前にご確認を！</h2>
          みなさまに気持ちよくご利用いただくために、以下の内容をご確認ください。

          <ul className="list-normal">
            <li>トラブル防止のため<span className="text-red text-bold">ご自身が作成されたゲームのみ</span>を公開してください。</li>
            <li>他者の<span className="text-red">権利を侵害</span>するゲームを公開しないでください<span className="text-muted">（問題が発生した場合はあなたに責任が生じます）</span></li>
            <li><span className="text-red">年齢制限が必要</span>と思われるゲームを公開しないでください<span className="text-muted">（過度にエッチなもの、過度に残酷な描写のあるものなど）</span></li>
            <li>プレイヤーの<span className="text-red">個人情報</span>を取得しないでください <span className="text-muted">（本名、住所、メールアドレスなど現実世界の情報）</span></li>
            <li>独自の<span className="text-red">決済/課金</span>システムを導入しないでください</li>
            <li><a href="https://anko.in/terms" className="normal" target="_blank" rel="noreferrer">利用規約</a>をお読みになり楽しくご利用ください</li>
          </ul>
        </section>

        <div className="form-section">
          <button type="button" className="btn btn-primary box-center" onClick={()=>{ClickRegistBtn()}}>この内容で登録する</button>
        </div>
      </form>

      <div id="games-add-progress">
        <section className="bg-section" style={{display:progress<PROGRESS_CD.DONE?  'block':'none'}}>
          <h2>サーバへ登録しています</h2>
          <ul className="list-normal">
            <li>公開完了まで10分程度かかります。この画面を閉じずにお待ち下さい。<br />（途中で画面を閉じてしまうと正常に公開されません）</li>
            <li>「サムネイル画像」を入力されていない場合、実際の処理はスキップされています。</li>
          </ul>
          <ol id="progress" className="list-normal">
            <ProgressListItem progid={PROGRESS_CD.READY} label="準備中"                       current={progress} error={!progressFb}/>
            <ProgressListItem progid={PROGRESS_CD.STEP1} label="ゲームファイルのアップロード" current={progress} error={!progressFb}/>
            <ProgressListItem progid={PROGRESS_CD.STEP2} label="ゲームファイルの内容を確認中" current={progress} error={!progressFb}/>
            <ProgressListItem progid={PROGRESS_CD.STEP3} label="サムネイル画像のアップロード" current={progress} error={!progressFb}/>
            <ProgressListItem progid={PROGRESS_CD.STEP4} label="基本情報の登録"               current={progress} error={!progressFb}/>
            <ProgressListItem progid={PROGRESS_CD.STEP5} label="公開の準備中"                 current={progress} error={!progressFb}/>
          </ol>
          <div id="progress-error" style={{display:progressFb?  'none':'block'}}>
            <Alert type="danger">
              <h3 className="text-red">エラーが発生しました。</h3>
              <p>登録中にエラーが発生しました。前の画面にもどり入力内容をご確認ください。</p>
              <form>
                <button className="btn btn-normal" type="button" onClick={async ()=>{ await windowToggle('input')}}>もどる</button>
              </form>
            </Alert>
          </div>
        </section>
        <section className="bg-section" style={{display:progress === PROGRESS_CD.DONE?  'block':'none'}}>
          <h2>ゲームが新たに公開されました！</h2>
          <Link to="/games">
            <button className="btn btn-normal" type="button" >もどる</button>
          </Link>
        </section>
      </div>
    </div>
  );
}

/**
 * 進捗リスト（li）
 *
 * @param {object} props
 */
function ProgressListItem(props){
  const id = Number(props.progid);
  const cur = props.current;
  let classname = '';
  let icon = null;

  // 現在実行中
  if(id === cur){
    classname = 'progress-do'
    icon = props.error? <img src={iconNg} alt="NG" width="20" height="20" style={{display:"inline", marginLeft:"10px"}}/>:<Spinner />
  }
  // これから
  else if(id > cur){
    classname = 'progress-todo'
  }
  // 完了
  else if(id < cur){
    classname = 'progress-done'
    icon = <img src={iconCheck} alt="OK" width="20" height="20" style={{display:"inline", marginLeft:"10px"}}/>
  }

  return(
    <li className={classname}>
      {props.label}
      {icon===null? null:icon}
    </li>
  )
}

export default GamesAdd;
