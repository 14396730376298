import { useEffect, useState } from 'react';
import Spinner from './Spinner';
import animateCSS from '../../libs/animate';
import {$q, sleep} from '../../libs/util';
import '../../style/loading.css';

/**
 * Now Loading
 *
 * @param {object} props
 */
function Loading(props){
  const [isShow, setIsShow] = useState(false);
  const wait = props.wait || 1000;

  useEffect(()=>{
    (async ()=>{
      await sleep(wait)   // n秒経過したらLoading画面を表示
      setIsShow(true)
      await animateCSS($q('.loading'), 'slideInUp');
    })();
  }, [ wait ])

  return(
    <div className="loading" style={{display:isShow? 'block':'none'}}>
      <Spinner /> <span className="loading-message">........Now Loading</span>
    </div>
  );
}

export default Loading;