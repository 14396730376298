/**
 * ログイン関係ライブラリ
 *
 */

//------------------------------------------------------------------------------
// ライブラリ
//------------------------------------------------------------------------------
import Message from './message';

//------------------------------------------------------------------------------
// 定数
//------------------------------------------------------------------------------
const CACHE_KEY = 'user'
const CACHE_EXPIRES = 1000 * 60 * 60 * 8     // 8時間

/**
 * セッション情報を取得
 *
 * @param {boolean} [force=false]
 */
function get(force=false){
  return new Promise((resolve, reject) => {
    const command = 'user'

    try{
      // キャッシュから取得
      if( force === false ){
        const cache = sessionStorage.getItem(CACHE_KEY)
        if( cache ){
          const data = JSON.parse(cache)
          if( data.registAt > (Date.now() - CACHE_EXPIRES) ){
            return resolve(data.user)
          }
          clearCache()   // 有効期限切れなら削除
        }
      }

      // キャッシュになければ新規に取得
      Message.setCommand(command, (data)=>{
        let buff = {};
        if(data.body.status && data.body.user){
          buff = data.body.user
          buff.isLogin = true

          // キャッシュに保存
          sessionStorage.setItem(CACHE_KEY, JSON.stringify({registAt: Date.now(), user: buff}))
        }
        else{
          buff.isLogin = false
        }
        return resolve(buff)
      })
      Message.listen()
      Message.send(command)
    }
    catch(e){
      return reject(e)
    }
  })
}

/**
 * ログアウト
 *
 */
function logout(){
  return new Promise((resolve, reject) => {
    const command = 'logout'

    try{
      Message.setCommand(command, (data)=>{
        if(data.body.status){
          clearCache()
          return resolve(true)
        }
        else{
          return resolve(false)
        }
      })
      Message.listen()
      Message.send(command)
    }
    catch(e){
      return reject(e)
    }
  })
}

/**
 * キャッシュを削除する
 *
 * @returns {void}
 */
function clearCache(){
  sessionStorage.removeItem(CACHE_KEY)
}

/**
 * ログイン済みユーザーか判定
 *
 * @returns {boolean}
 */
function isAuthUser(){
  return ('isLogin' in window.User) && (window.User.isLogin === true)
}

/**
 * ログインページへリダイレクト
 */
function goLoginPage(){
  const url = `https://anko.in/user/login?${ new URLSearchParams({ret: window.location.href}).toString() }`;
  window.location.href = url;
}

//------------------------------------------------------------------------------
// export
//------------------------------------------------------------------------------
const sess = {
  get,
  logout,
  clearCache,
  isAuthUser,
  goLoginPage
}
export default sess;