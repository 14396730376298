/**
 * Validation module
 *
 * @module validation
 * @author M.Katsube
 */

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import util from './util'
import validUrl from 'valid-url'
import validMailAddress from 'email-validator'

/**
 * [validation] URL（汎用）
 *
 * @param {string} value
 * @returns {boolean}
 */
function validURL(value){
  return (
      (typeof value === 'string')
      && (validUrl.isUri(value))
  )
}

/**
 * [validation] メールアドレス
 *
 * @param {string} value
 * @returns {boolean}
 */
function validEMail(value){
  return(
      (typeof value === 'string')
      && (validMailAddress.validate(value))
  )
}

/**
 * [validation] アプリファイル
 *
 * @param {object} value
 * @returns {boolean}
 */
function validAppFile(value){
  return (
      (typeof value === 'object')
      && (value.type === 'application/zip' || value.type === 'application/x-zip-compressed')
      && (value.size <= 1024 * 1024 * 100)  // 50M -> 100M
  );
}

/**
 * [validation] ゲーム名
 *
 * @param {string} value
 * @returns {boolean}
 */
function validGameName(value){
  return(
    (value !== '')
      && (typeof value === 'string')
      && (util.betweenCharaLength(value, 2, 64))
  )
}

/**
 * [validation] 対応機種
 *
 * @param {string} value
 * @returns {boolean}
 */
function validPlatform(value){
  return(
    (value !== '')
      && (typeof value === 'string')
      && (value.match(/^([1-3])$/) !== null)
  )
}

/**
 * [validation] カテゴリー
 *
 * @param {string} value
 * @returns {boolean}
 */
function validCategory(value){
  return(
    (value !== '')
      && (typeof value === 'string')
      && (value.match(/^([0-9]{1,})$/) !== null)
      && ( (1<= Number(value) && Number(value) <= 11) || Number(value) === 99 )
  )
}

/**
 * [validation] ゲームID
 *
 * @param {string} value
 * @returns {boolean}
 */
function validGameID(value){
  return(
    (value !== '')
      && (typeof value === 'string')
      && (value.match(/^([0-9a-zA-Z\-_]{1,})$/) !== null)
      && (util.betweenCharaLength(value, 3, 64))
  )
}

/**
 * [validation] 操作方法
 *
 * @param {string} value
 * @returns {boolean}
 */
function validManual(value){
  return(
    (value !== '')
      && (typeof value === 'string')
      && (util.betweenCharaLength(value, 1, 256))
  )
}

/**
 * [validation] 説明文
 *
 * @param {string} value
 * @returns {boolean}
 */
function validDescription(value){
  return (
    (value !== '')
    && (typeof value === 'string')
    && (util.betweenCharaLength(value, 1, 2048))
  )
}

/**
 * [validation] プレイ時間
 *
 * @param {string} value
 * @returns {boolean}
 */
function validPlaytime(value){
  return (
    (value !== '')
      && (typeof value === 'string')
      && (value.match(/^([0-9]{1,})$/) !== null)
      && ( 0 <= Number(value) && Number(value) <= 999 )
  )
}

/**
 * [validation] タグ
 *
 * @param {string} value
 * @returns {boolean}
 */
function validTag(value){
  return (
    (value !== '')
      && (typeof value === 'string')
      && (util.betweenCharaLength(value, 2, 8))
  )
}

/**
 * [validation] サムネイル画像
 *
 * @param {string} value
 * @returns {boolean}
 */
function validThmubnail(value){
  return (
      (typeof value === 'object')
      && (value.type === 'image/jpeg' || value.type === 'image/png')
      && (value.size <= 1024 * 1024 * 3)
  );
}

/**
 * [validation] コメント機能設定
 *
 * @param {string} value
 * @returns {boolean}
 */
function validCommentConfig(value){
  return(
      (typeof value === 'string')
      && (value.match(/^(0|1|2)$/) !== null)
  )
}

/**
 * [validation] スタジオ名
 *
 * @param {string} value
 * @returns {boolean}
 */
function validStudioName(value){
  return(
      (typeof value === 'string')
      && (util.betweenCharaLength(value, 2, 64))
  )
}

/**
 * [validation] スタジオID
 *
 * @param {string} value
 * @returns {boolean}
 */
function validStudioID(value){
  return(
      (typeof value === 'string')
      && (value.match(/^([0-9a-zA-Z\-_]{3,64})$/) !== null)
  )
}

/**
 * [validation] Twitterアカウント
 *
 * @param {string} value
 * @returns {boolean}
 */
function validTwitter(value){
  return(
      (typeof value === 'string')
      && (value.match(/^([0-9a-zA-Z_]{1,15})$/) !== null)
  )
}

/**
 * [validation] YouTube URL
 *
 * @param {string} value
 * @returns {boolean}
 */
function validYoutube(value){
  return(
    validURL(value)
    && (value.match(/^(https?:\/\/)?(www\.)?youtube\.com\//) !== null)
  )
}

/**
 * [validation] discord URL
 *
 * @param {string} value
 * @returns {boolean}
 */
function validDiscord(value){
  return(
    validURL(value)
    && (value.match(/^(https?:\/\/)?discord\.gg\/.*$/) !== null)
  )
}

/**
 * [validation] WebサイトURL
 *
 * @param {string} value
 * @returns {boolean}
 */
function validBlog(value){
  return(
    validURL(value)
  )
}

/**
 * [validation] スタジオアイコン画像
 *
 * @param {object} value
 * @returns {boolean}
 */
function validStudioIconImage(value){
  return (
      (typeof value === 'object')
      && (value.type === 'image/jpeg' || value.type === 'image/png')
      && (value.size <= 1024 * 1024 * 1)
  );
}

/**
 * [validation] スタジオ背景画像
 *
 * @param {object} value
 * @returns {boolean}
 */
function validStudioCoverImage(value){
  return (
      (typeof value === 'object')
      && (value.type === 'image/jpeg' || value.type === 'image/png')
      && (value.size <= 1024 * 1024 * 3)
  );
}

/**
 * [validation] プラン名
 *
 * @param {string} value
 * @returns {boolean}
 */
function validPlanName(value){
  return(
      (typeof value === 'string')
      && (util.betweenCharaLength(value, 2, 64))
  )
}

/**
 * [validation] プラン価格
 *
 * @param {string} value
 * @returns {boolean}
 */
function validPlanPrice(value){
  return(
      (typeof value === 'string')
      && (300 <= Number(value) && Number(value) <= 10000)
  )
}

/**
 * [validation] プラン特典
 *
 * @param {string} value
 * @returns {boolean}
 */
function validPlanBenefit(value){
  return(
      (typeof value === 'string')
      && (util.betweenCharaLength(value, 16, 1024))
  )
}

/**
 * [validation] プラン 銀行CD
 *
 * @param {string} value
 * @returns {boolean}
 */
function validPlanBankCD(value){
  return(
      (typeof value === 'string')
      && (value.match(/^([0-9]{4})$/) !== null)
  )
}

/**
 * [validation] プラン 銀行支店CD
 *
 * @param {string} value
 * @returns {boolean}
 */
function validPlanBankSiten(value){
  return(
      (typeof value === 'string')
      && (value.match(/^([0-9]{3})$/) !== null)
  )
}

/**
 * [validation] プラン 銀行口座番号
 *
 * @param {string} value
 * @returns {boolean}
 */
function validPlanBankAccount(value){
  return(
      (typeof value === 'string')
      && (value.match(/^([0-9]{7})$/) !== null)
  )
}

/**
 * [validation] プラン 銀行名義人
 *
 * @param {string} value
 * @returns {boolean}
 */
function validPlanBankMeigi(value){
  return(
      (typeof value === 'string')
      && (util.betweenCharaLength(value, 1, 64))
  )
}

/**
 * [validation] プラン 本名
 *
 * @param {string} value
 * @returns {boolean}
 */
function validPlanHonmyou(value){
  return(
      (typeof value === 'string')
      && (util.betweenCharaLength(value, 1, 64))
  )
}

/**
 * [validation] プラン 特商法用メールアドレス
 *
 * @param {string} value
 * @returns {boolean}
 */
function validPlanBusinessMail(value){
  return( validEMail(value) )
}

//-----------------------------------------------------------------------------
// export
//-----------------------------------------------------------------------------
const validation = {
  // 汎用
  validURL,
  validEMail,

  // ゲーム登録
  validAppFile,
  validGameName,
  validPlatform,
  validCategory,
  validGameID,
  validManual,
  validDescription,
  validPlaytime,
  validTag,
  validThmubnail,
  validCommentConfig,

  // スタジオ
  validStudioName,
  validStudioID,
  validTwitter,
  validYoutube,
  validDiscord,
  validBlog,
  validStudioIconImage,
  validStudioCoverImage,

  // プラン
  validPlanName,
  validPlanPrice,
  validPlanBenefit,
  validPlanBankCD,
  validPlanBankSiten,
  validPlanBankAccount,
  validPlanBankMeigi,
  validPlanHonmyou,
  validPlanBusinessMail
}
export default validation;
