/**
 * message通信
 *
 * exapmle:
 *   import Message from './message';
 *
 *   // 受信側を準備
 *   Message.setCommand('logout', (data)=>{
 *     console.log(data)
 *     Message.terminate()
 *   })
 *   // 待ち受け開始
 *   Message.listen()
 *
 *   // 送信
 *   Message.send('logout')
 */

//------------------------------------------------------------------------------
// 定数
//------------------------------------------------------------------------------
const TARGET_ID = 'AnkoinDataBridge'
const TARGET_URL = 'https://anko.in/user/bridge.html'
const COMMANDS = {}

let TARGET_LOADED = false;

/**
 * コマンドを実行
 *
 * @param {object} e
 */
const runCommand = (e)=>{
  if(('cmd' in e.data) && (e.data.cmd in COMMANDS) && (typeof COMMANDS[e.data.cmd] === 'function')){
    COMMANDS[e.data.cmd](e.data)
  }
}

/**
 * message受け取り待ち
 */
function listen(){
  // iframeが存在しなければ作成
  if( ! document.getElementById(TARGET_ID) ){
    const iframe = document.createElement('iframe')
    iframe.id = TARGET_ID
    iframe.src = TARGET_URL
    iframe.style.display = 'none'
    document.body.appendChild(iframe)
  }

  document.getElementById(TARGET_ID).addEventListener('load', ()=>{
    TARGET_LOADED = true
    window.addEventListener('message', runCommand)
  })
}

/**
 * message受け取り待ち解除
 */
function terminate(){
  window.removeEventListener('message', runCommand)

  // iframeを削除
  if( document.getElementById(TARGET_ID) ){
    document.getElementById(TARGET_ID).remove()
  }
}

/**
 * メッセージ送信
 *
 * @param {string} cmd
 * @returns {boolean}
 */
function send(cmd){
  let count = 0
  const intervalid = setInterval(()=>{
    if( TARGET_LOADED ){
      document.getElementById(TARGET_ID).contentWindow.postMessage(cmd, '*')
      clearInterval(intervalid)
    }
    else{
      // 10秒間トライしても完了しなければ失敗
      if( count++ > 100 ){
        clearInterval(intervalid)
        throw new Error('message.send - target not loaded')
      }
    }
  }, 100)
}

/**
 * コマンドをセット
 *
 * @param {string} cmd
 * @param {function} func
 * @returns {boolean}
 */
function setCommand(cmd, func){
  if( typeof cmd === 'string' && typeof func === 'function'){
    COMMANDS[cmd] = func
    return true
  }
  return false
}

/**
 * コマンドを削除
 *
 * @param {string} cmd
 * @returns {boolean}
 */
function removeCommand(cmd){
  if( typeof cmd === 'string' && cmd in COMMANDS ){
    delete COMMANDS[cmd]
    return true
  }
  return false
}


//------------------------------------------------------------------------------
// export
//------------------------------------------------------------------------------
const exports = {
  listen,
  terminate,
  send,
  setCommand,
  removeCommand
}
export default exports