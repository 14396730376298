/**
 * InputGamename Component
 *
 **/

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import { useState, useEffect } from 'react';

// ライブラリ
import validation from '../libs/validation'

// スタイル
import "../style/base.css"

const optionsDefault = {
  id: 'gamename',
  className: '',
  placeholder: '例：はじける！パンダのもり',
}

/**
 * InputGameName
 *
 */
function InputGameName(props){
  const options = Object.assign(optionsDefault, props)
  const [className, setClassName] = useState(options.className)
  const [feedback, setFeedback] = useState(true)
  delete options['className']
  delete options['feedback']

  useEffect(()=>{
    setFeedback(props.feedback)
  }, [props.feedback])

  //-------------------------------
  // エラースタイルに変更
  //-------------------------------
  const showValidResult = (result) => {
    let newClass = className.replace('form-invalid', '').replace('form-valid', '')
    newClass += (result)?  ' form-valid':' form-invalid'

    setClassName(newClass)
    setFeedback(result)
  }

  //-------------------------------
  // 入力値のリアルタイムチェック
  //-------------------------------
  const checkGameName = (value)=>{
    if( value === '' || ! validation.validGameName(value) ){
      showValidResult(false)
    }
    else{
      showValidResult(true)
    }
  }

  return(
    <>
      <input type="text" {...options} className={className} onChange={ e => checkGameName(e.target.value) } />
      <p className="invalid-feedback" style={{display:feedback?  'none':'block'}}>
        ゲーム名を正しく入力してください
      </p>
    </>
  );
}

export default InputGameName;