/**
 * App component
 *
 * @author M.Katsube
 */

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

//-------------------------------------
// Components
//-------------------------------------
// パーツ
import GHeader from './GHeader';
import GAlert from './GAlert';
import GNavi from './GNavi';

// 各ページ内容
// import Home from './Home';
import Games from './Games';
import GamesAdd from './GamesAdd';
import GamesConfig from './GamesConfig';
import Sponsors from './Sponsors';
import Wallet from './Wallet';
import Messages from './Messages';
import Config from './Config';
import Notes from './Notes';
import Logout from './Logout';

/**
 * App component
 *
 */
function App(){
  return(
    <BrowserRouter>
      <GHeader />
      <GAlert />
      <div className="content w-full h-full mt-4px flex-1">
        <div className="content_container container-box w-full h-full overflow-x-hidden grid grid-content-base tab:block">
          <GNavi />
          <main className="main top h-full min-w-0 min-h-0 flex flex-col relative">
            <Routes>
              <Route exact path="/"    element={<Navigate to="/games" replace />} />
              <Route path="/games"     element={<Games />} />
              <Route path="/games/add" element={<GamesAdd />} />
              <Route path="/games/config/:id" element={<GamesConfig />} />
              <Route path="/sponsors"  element={<Sponsors />} />
              {/*}
              <Route path="/wallet"    element={<Wallet />} />
              <Route path="/notes"     element={<Notes />} />
              <Route path="/messages"  element={<Messages />} />
              */}
              <Route path="/config"    element={<Config />} />
              <Route path="/logout"    element={<Logout />} />
              <Route path="*"          element={<div>404 Not Found</div>} />
            </Routes>
          </main>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;