/**
 * util モジュール
 *
 * @module util
 * @author M.Katsube
 */

//--------------------------------------------------
// モジュール
//--------------------------------------------------

//--------------------------------------------------
// 定数
//--------------------------------------------------

/**
 * 文字列長のバイト数を返却
 *
 * @param {string} str
 * @returns {number}
 */
function countByte(str){
  return(encodeURIComponent(this).replace(/%../g,"x").length)
}

/**
 * 文字列長(byte)が一定の範囲内かチェック
 *
 * @param {string} str
 * @param {number} min
 * @param {number} max
 * @returns {boolean}
 */
function betweenCharaByte(str, min, max){
  const len = countByte(str)
  return ((min <= len) && (len <= max))
}

/**
 * 文字列内の文字数をカウント（notバイト数）
 *
 * @param {string} str
 * @returns {number}
 */
function countChara(str){
  return [...str].length
}

/**
 * 文字数が一定の範囲内かチェック（notバイト数）
 *
 * @param {string} str チェック対象の文字列
 * @param {number} min 最小文字数
 * @param {number} max 最大文字数
 * @returns {boolean}
 */
function betweenCharaLength(str, min, max){
  const len = countChara(str)
  return ((min <= len) && (len <= max))
}


/**
 * 時間をYYYY-MM-DD hh:mm:ss形式で取得
 *
 * @param {number|string} time - UNIXTIMEなど
 * @returns {string}
 */
function formatDate(time=null){
  const obj = (time === null)?  new Date():new Date(time)
  return obj.toLocaleString('sv')
}

/**
 * 現在時間のUNIX TIMEを返却
 *
 * @param {number} addsec 加算する秒数
 * @returns {number}
 */
function getUnixtime(addsec=0){
  return Math.floor((new Date().getTime() / 1000) + addsec)
}

/**
 * getElementById wrapper
 *
 * @param {string} id
 * @returns {object}
 */
export function $(id){
  return document.getElementById(id)
}

/**
 * querySelector wrapper
 *
 * @param {string} selector
 * @returns {object}
 */
export function $q(selector){
  return document.querySelector(selector)
}

/**
 * sleep
 *
 * @param {number} [sec=1000]
 * @return {Promise}
 */
export function sleep(sec=1000){
  return new Promise((resolve, reject) => {
      setTimeout(() => {
          resolve()
      }, sec)
  })
}

/**
 * 指定文字数で切り取る
 *
 * @param {string} str
 * @param {number} max
 * @param {string} [add="..."]
 * @returns {string}
 */
export function cutString(str, max, add='...'){
  if(str.length > max){
    return str.substr(0, max) + add
  }
  return str
}

//--------------------------------------------------
// exports
//--------------------------------------------------
const util = {
  countByte,
  betweenCharaByte,
  countChara,
  betweenCharaLength,
  formatDate,
  getUnixtime,
  $,
  $q,
  sleep,
  cutString
}
export default util;
