import { useState } from 'react';
import animateCSS from '../../libs/animate';
import '../../style/alert.css'

function Alert(props){
  const [show, setShow] = useState(true);
  const {type} = props;
  const id = `alert-${Math.floor(Math.random() * 10000)}`;

  const closeAlert = async () => {
    await animateCSS(id, 'fadeOut')
    setShow(false)
  }

  return (
    <div id={id} className={`alert alert-${type}`} style={{display:show? 'block':'none'}}>
      <button type="button" className="close" onClick={closeAlert}>
        <span>&times;</span>
      </button>
      {props.children}
    </div>
  )
}

export default Alert;