/**
 * CSSアニメ
 *
 * @param {string|object} element "foo" or document.getElementById('foo')
 * @param {string} animation  "bounce"
 * @param {string} [prefix='animate__']
 * @see https://animate.style/
 */
export default function animateCSS (element, animation, prefix='animate__'){
  return new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = (typeof element === 'string')?  document.getElementById(element):element
    if(!node){
      return;
    }

    node.classList.add(`${prefix}animated`, animationName);

    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
    }

    node.addEventListener('animationend', handleAnimationEnd, {once: true});
  })
}
