import '../../style/card.css'

function Card(props) {
  return(
    <div className="card">
      <CardImage image={props.image} link={props.link}/>
      <div className="card-box">
        <CardTitle title={props.title} />
        <div className="card-text">
          {props.children}
        </div>
      </div>
    </div>
  );
}

function CardImage(props){
  const redirect = (link)=>{
    window.open(link, '_blank')
  }

  if(!props.image){
    return null
  }
  else{
    return(
      <div className="card-image" onClick={()=>{redirect(props.link)}}>
        <img src={props.image} alt="" />
      </div>
    )
  }
}

function CardTitle(props){
  if(!props.title){
    return null
  }
  else{
    return(
      <h3 className="card-title">{props.title}</h3>
    )
  }
}

export default Card;