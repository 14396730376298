/**
 * グローバルアラート
 *
 * @returns {undefined}
 */
function GAlert() {
  return (
    <>
    { /*
    <div className="header-alert w-full">
        <div className="header-alert_box w-full bg-color_LightGray px-4 py-0 mb-4px">
            <div className="header-alert_container container-box py-12px px-0 flex items-center justify-start">
                <i className="icon icon-info w-20px h-20px bg-color_Primary my-0 mr-6px ml-24px flex-shrink-0 tab:mr-8px tab:ml-0"></i>
                <span className=" text-14px space-x-0">【お知らせ】2021-09-10 12:00〜14:00の間メンテナンスを行います。この時間帯はサービスをご利用いただけません。</span>
            </div>
        </div>
        <div className="header-alert_box w-full bg-color_LightGray px-4 py-0">
            <div className="header-alert_container container-box py-12px px-0 flex items-center justify-start">
                <i className="icon icon-info w-20px h-20px bg-color_Primary my-0 mr-6px ml-24px flex-shrink-0 tab:mr-8px tab:ml-0"></i>
                <span className=" text-14px space-x-0">【お知らせ】2021-09-10 12:00〜14:00の間メンテナンスを行います。この時間帯はサービスをご利用いただけません。</span>
            </div>
        </div>
    </div>
    */}
    </>
  );
}

export default GAlert;
