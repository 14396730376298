/**
 * Spinner Component
 *
 * @see https://github.com/SamHerbert/SVG-Loaders
 */

const optionsDefault = {
  width: 25,
  height: 25,
  stroke: 'lightblue',
  duration: '1s',
  style: {
    display: 'inline',
    margin: '5px'
  }
}

function Spinner(props){
  const options = Object.assign(optionsDefault, props)
  return(
    <svg style={options.style} width={options.width} height={options.height} viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke={options.stroke}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="3">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
          <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur={options.duration}
                  repeatCount="indefinite"/>
          </path>
        </g>
      </g>
    </svg>
  );
}

export default Spinner;