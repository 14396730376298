import { useState } from 'react';
import Playtime from '../data/playtime.json';

function InputPlaytime(props){
  const optionsDefault = {
    id: "playtime",
    name: "playtime",
    className: "",
    first: {value:"0", label:"選択してください"},
    selected: 0
  }
  const options = Object.assign(optionsDefault, props);
  const [defaultItem, setDefaultItem] = useState(options.selected);

  return (
    <select id={options.id} name={options.name} className={options.className}
            value={defaultItem}
            onChange={ (e)=>{ setDefaultItem(e.target.value) } }>

      {/* 最初のプルダウン */}
      <PlaytimeOption key={options.first.value} label={options.first.label} value={options.first.value}/>

      {/* カテゴリ一覧 */}
      {Playtime.map(i => (
        <PlaytimeOption
            key={i.id}
            label={i.ja}
            value={i.id}
        />
      ))}
    </select>
  );
}

function PlaytimeOption(props){
  return (
    <option value={props.value}>{props.label}</option>
  );
}

export default InputPlaytime;