import config from '../config'

/**
 * APIリクエスト
 *
 * @param {string} path    '/user/login'
 * @param {string} method  'GET', 'POST'
 * @param {object} params  {id:"xxx", passwd:"xxxx"}
 * @returns {Promise<object>}
 */
export default async function request(path, method='GET', params=null){
  let url = `${config('API.REST')}${path}`;
  let response;

  try{
    if( /^GET$/i.test(method) ){
      url += (params !==null)? '?' + new URLSearchParams(params).toString():'';
      response = await window.fetch(url)
    }
    else if( /^POST$/i.test(method) ){
      response = await window.fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(params === null? {}:params)
      })
    }

    const json = await response.json();
    console.log('[API.request] response', json)
    return json;
  }
  catch(e){
    console.error(e.message)
    return(false)
  }
}