import config from './default.json'

/**
 * 設定を返却する
 *
 * @param {string} [key=null]
 * @returns {any}
 */
export default function get(key=null){
  // 未指定時は全て返す
  if( key === null ){
    return config
  }

  // ドットが含まれている場合は絞り込む
  if( key.indexOf('.') !== -1 ){
    const keys = key.split('.')
    let result = config
    for( let i=0; i<keys.length; i++ ){
      result = result[keys[i]]
      if( result === undefined ){
        return null
      }
    }
    return result
  }
  // キー単体の場合はそのまま返す
  else if(key in config){
    return config[key]
  }
  return null
}