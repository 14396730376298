/**
 * 設定 /config
 *
 */

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import { useState, useEffect } from 'react';

// コンポーネント
import DropzoneArea from './ui/DropzoneArea';
import Alert from './ui/Alert';
import Loading from './ui/Loading';
import Spinner from './ui/Spinner';

// ライブラリ
import { $ } from '../libs/util';
import scrollTop from "../libs/scrollTop";
import API from "../libs/api";
import upload from "../libs/upload";
import validation from '../libs/validation';
import session from '../libs/session';

// 設定
import config from '../config';

// スタイル
import "../style/base.css"
import "../style/config.css"

//----------------------------------------------------
// 定数
//----------------------------------------------------
const LOAD_STATUS = {
  NOW: 1,
  DONE: 2,
  ERROR: 3
}

/**
 * Config component
 *
 */
function Config() {
  // 画面の全体制御
  const [loading, setLoading] = useState(LOAD_STATUS.NOW);
  const [isLoading, setIsLoading] = useState(false);

  // ファイル
  const [iconImage, setIconImage] = useState(null);
  const [iconPreview, setIconPreview] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);

  // エラー管理
  const [nameFb, setNameFb] = useState(true);
  const [nameFb2, setNameFb2] = useState(true);
  const [sidFb, setSidFb] = useState(true);
  const [sidFb2, setSidFb2] = useState(true);
  const [iconImageFb, setIconImageFb] = useState(true);
  const [iconImageFb2, setIconImageFb2] = useState(true);
  const [coverImageFb, setCoverImageFb] = useState(true);
  const [coverImageFb2, setCoverImageFb2] = useState(true);
  const [twitterFb, setTwitterFb] = useState(true);
  const [youtubeFb, setYoutubeFb] = useState(true);
  const [discordFb, setDiscordFb] = useState(true);
  const [blogFb, setBlogFb] = useState(true);

  // 最初の1回だけ
  useEffect( ()=>{
    // 上部へスクロール
    scrollTop();

    // サーバから最新のデータを取得
    (async ()=>{
      await loadServerData()
    })();
  }, [ ])


  /**
   * サーバからデータ取得
   *
   * @returns {Promise<boolean>}
   */
  const loadServerData = async () => {
    const ret = await API('/creator/studio/config/get', 'POST', { sessid: window.User.sessid });

    // エラー発生時
    if( ! ret || ret.head.status === false ){
      setLoading(LOAD_STATUS.ERROR)
      return false;
    }
    // データが存在しない
    else if( ret.data === null ){
      setLoading(LOAD_STATUS.DONE)
      return true;
    }

    // データをセット
    $('sname').value   = ret.data.name
    $('sid').value     = ret.data.sid
    $('sid').setAttribute('disabled', true)
    $('twitter').value = ret.data.twitter
    $('youtube').value = ret.data.youtube
    $('discord').value = ret.data.discord
    $('blog').value    = ret.data.blog
    if(ret.data.icon !== null){
      setIconPreview(`${config('HOST.IMAGE')}/${ret.data.icon}`)
    }
    if(ret.data.cover !== null){
      setCoverPreview(`${config('HOST.IMAGE')}/${ret.data.cover}`)
    }
    setLoading(LOAD_STATUS.DONE)
  }

  /**
   * 登録処理
   *
   */
  const ClickRegistBtn = async (type) => {
    let result

    setIsLoading(true)
    switch(type){
      case "basic":
        result = await RegistBasic();
        break;
      case "icon":
        result = await RegistIcon();
        break;
      case "cover":
        result = await RegistCover();
        break;
      case "sns":
        result = await RegistSns();
        break;
      default:
        break;
    }
    setIsLoading(false)

    if(result === true){
      window.alert('登録しました');
    }
    else if( result === null ){
      ;
    }
    else{
      window.alert('登録に失敗しました');
    }
  }

  /**
   * 「基本情報」を登録
   *
   * @returns {boolean}
   */
  const RegistBasic = async () => {
    const name = $("sname").value;
    const sid = $("sid").value;
    const siddisable = $("sid").getAttribute('disabled');

    // エラー表示をリセット
    setNameFb(true)
    setNameFb2(true)
    setSidFb(true)
    setSidFb2(true)

    //--------------------------------------
    // Validation
    //--------------------------------------
    const errors = [];
    if( ! validation.validStudioName(name) ){
      errors.push('group-sname')
      setNameFb(false)
    }
    if( ! siddisable && ! validation.validStudioName(sid) ){
      errors.push('group-sid')
      setSidFb(false)
    }

    if( errors.length > 0 ){
      scrollTop(errors[0])
      return false;
    }

    //--------------------------------------
    // 登録
    //--------------------------------------
    const params = {
      sessid: window.User.sessid,
      name,
      sid: siddisable ? null : sid,
    }
    const res = await API('/creator/studio/config/set', 'POST', params);
    if( res !== false && res.head.status){
      $('sid').setAttribute('disabled', true)
      session.clearCache()

      window.alert('設定が完了しました。\nもう一度ログインをお願いします');
      window.location.href = 'https://anko.in/user/login?ret=' + encodeURIComponent(window.location.href);
      return null;
    }

    switch( res.head.error.message.substring(0,6) ){
      case "E11001":  // name
        setNameFb2(false);
        break;
      case "E11008":  // sid
        setSidFb(false);
        break;
      case "E12001":  // sidが重複
        setSidFb2(false);
        break;
      default:
        break;
    }
    return false
  }

  /**
   * 「アイコン」を登録
   */
  const RegistIcon = async () => {
    // エラー表示をリセット
    setIconImageFb(true)
    setIconImageFb2(true)

    //--------------------------------------
    // Validation
    //--------------------------------------
    if( iconImage === null || ! validation.validStudioIconImage(iconImage) ){
      setIconImageFb(false);
      return false;
    }

    //--------------------------------------
    // 登録
    //--------------------------------------
    // 画像アップロード
    const ret = await upload.studioIcon(iconImage, window.User.sessid)
    if( ret === false ){
      setIconImageFb2(false);
      return false;
    }
    // 登録
    const params = {
      sessid: window.User.sessid,
      icon: ret.data.path,
    }
    const res = await API('/creator/studio/config/set', 'POST', params);
    if( res !== false && res.head.status ){
      setIconPreview(null)
      setIconPreview(`${config('HOST.IMAGE')}/${ret.data.path}`)
      return true;
    }
    setIconImageFb2(false);
    return false
  }

  /**
   * 「カバー画像」を登録
   */
  const RegistCover = async () => {
    // エラー表示をリセット
    setCoverImageFb(true)
    setCoverImageFb2(true)

    //--------------------------------------
    // Validation
    //--------------------------------------
    if( coverImage === null || ! validation.validStudioCoverImage(coverImage) ){
      setCoverImageFb(false);
      return false;
    }

    //--------------------------------------
    // 登録
    //--------------------------------------
    // 画像アップロード
    const ret = await upload.studioCover(coverImage, window.User.sessid)
    if( ret === false ){
      setCoverImageFb2(false);
      return false;
    }
    // 登録
    const params = {
      sessid: window.User.sessid,
      cover: ret.data.path,
    }
    const res = await API('/creator/studio/config/set', 'POST', params);
    if( res !== false && res.head.status ){
      setCoverPreview(null)
      setCoverPreview(`${config('HOST.IMAGE')}/${ret.data.path}`)
      return true;
    }
    setCoverImageFb2(false);
    return false
  }

  /**
   * 「SNS」を登録
   *
   * @returns {boolean}
   */
  const RegistSns = async () => {
    const twitter = $("twitter").value;
    const youtube = $("youtube").value;
    const discord = $("discord").value;
    const blog = $("blog").value;

    // エラー表示をリセット
    setTwitterFb(true)
    setYoutubeFb(true)
    setDiscordFb(true)
    setBlogFb(true)

    //--------------------------------------
    // Validation
    //--------------------------------------
    const errors = [];
    if( twitter !== "" && ! validation.validTwitter(twitter) ){
      errors.push('group-twitter')
      setTwitterFb(false)
    }
    if( youtube !== "" && ! validation.validYoutube(youtube) ){
      errors.push('group-youtube')
      setYoutubeFb(false)
    }
    if( discord !== "" && ! validation.validDiscord(discord) ){
      errors.push('group-discord')
      setDiscordFb(false)
    }
    if( blog !== "" &&! validation.validBlog(blog) ){
      errors.push('group-blog')
      setBlogFb(false)
    }

    // 入力エラー
    if( errors.length > 0 ){
      scrollTop(errors[0])
      return null;
    }
    // すべて空白
    if(twitter === "" && youtube === "" && discord === "" && blog === ""){
      return null;
    }


    //--------------------------------------
    // 登録
    //--------------------------------------
    const params = {
      sessid: window.User.sessid
    }
    if( twitter !== "" ) params.twitter = twitter;
    if( youtube !== "" ) params.youtube = youtube;
    if( discord !== "" ) params.discord = discord;
    if( blog !== "" ) params.blog = blog;

    const res = await API('/creator/studio/config/set', 'POST', params);
    if( res !== false && res.head.status ){
      return true;
    }

    switch( res.head.error.message.substring(0,6) ){
      case "E11004":  // twitter
        setTwitterFb(false);
        break;
      case "E11005":  // youtube
        setYoutubeFb(false);
        break;
      case "E11006":  // discord
        setDiscordFb(false);
        break;
      case "E11007":  // blog
        setBlogFb(false);
        break;
      default:
        break;
    }
    return false;
  }

  return (
    <div id="config" className="bg">
      <h1>設定</h1>

      <div id="nowloading" style={{display:loading === LOAD_STATUS.NOW? 'block':'none'}}>
        <Loading />
      </div>

      <div id="loading-error" style={{display:loading === LOAD_STATUS.ERROR? 'block':'none'}}>
        <Alert type="danger">
          <h2>通信エラーが発生しました</h2>
          申し訳ありませんが、しばらく経ってから再度お試しください。
        </Alert>
      </div>

      <div id="config-main" style={{display:loading === LOAD_STATUS.DONE? 'block':'none'}}>
        <Alert type="info">
          <h2>ご注意</h2>
          <ul className="list-normal">
            <li>このページでご入力いただいたすべての情報は<span className="text-red text-bold">一般に公開されます</span></li>
            <li>入力された内容は「<span className="text-bold">この内容で保存する</span>」ボタンを押すまで保存されません。</li>
          </ul>
        </Alert>

        <section id="group-basic" className="bg-section">
          <h2>基本情報</h2>
          <ul className="list-normal">
            <li><span className="text-red">★</span>がついている項目は必ずご入力ください。</li>
            <li>メニュー上にあるスタジオ名は再度ログインした際に反映されます</li>
          </ul>

          <div id="group-sname" className="form-section">
            <label className="form-label">スタジオ名 <span className="text-red text-bold">★</span></label>
            <input type="text" id="sname" className="form-control" placeholder="例：ankoinゲームス" disabled={isLoading}/>
            <p className="invalid-feedback" style={{display:nameFb?  'none':'block'}}>
              スタジオ名を正しく入力してください。
            </p>
            <p className="invalid-feedback" style={{display:nameFb2?  'none':'block'}}>
              このスタジオ名は利用できません。
            </p>
            <p className="help-block">
              ※2〜64文字。<a href="https://www.j-platpat.inpit.go.jp/" className="normal" target="_blank" rel="noreferrer">商標</a>にお気をつけください。
            </p>
          </div>
          <div id="group-sid" className="form-section">
            <label className="form-label">スタジオID <span className="text-red text-bold">★</span></label>
            <input type="text" id="sid" className="form-control" placeholder="例：ankoingames"/>
            <p className="invalid-feedback" style={{display:sidFb?  'none':'block'}}>
              スタジオIDを正しく入力してください。
            </p>
            <p className="invalid-feedback" style={{display:sidFb2?  'none':'block'}}>
              このスタジオIDは利用できません
            </p>
            <p className="help-block">
              ※URLに利用されます。この項目は<span className="text-red">あとから変更できません。</span><br />
              ※3〜64文字。半角英数字、ハイフン(-)、アンダースコア(_)のみでご入力ください。<br />
              ※すでに他の方が使用されているIDは利用できません。<br />
            </p>
          </div>

          <div className="form-section">
            <button type="button" className="btn btn-primary box-center" onClick={async ()=>{await ClickRegistBtn('basic')}} disabled={isLoading}>
              この内容で保存する
              <span style={{display:isLoading? 'inline':'none'}}><Spinner /></span>
            </button>
            <p className="help-block text-center">(「基本情報」のみ保存します)</p>
          </div>
        </section>

        <section id="group-icon" className="bg-section">
          <h2>アイコン</h2>
          <ul className="list-normal">
            <li>スタジオを象徴するアイコンです。未登録の場合は汎用的な画像が表示されます。</li>
            <li><span className="text-red">1Mbyte以下のJPEG, PNG形式の画像</span>が利用できます。</li>
            <li>512px x 512pxの画像がおすすめです。</li>
          </ul>
          <div className="form-section">
            <DropzoneArea id="icon" type="image" maxSize={1024 * 1024 * 1} set={setIconImage}/>
            <p className="invalid-feedback" style={{display:iconImageFb? 'none':'block'}}>
              ファイルを正しくセットしてください。
            </p>
            <p className="invalid-feedback" style={{display:iconImageFb2? 'none':'block'}}>
              ファイルのアップロードに失敗しました。
            </p>
          </div>
          <div id="icon-preview" style={{display:iconPreview===null? 'none':'block'}}>
              <h3>現在設定中のアイコン</h3>
              <img src={iconPreview} alt="icon" />
          </div>
          <div className="form-section">
            <button type="button" className="btn btn-primary box-center" onClick={async ()=>{await ClickRegistBtn('icon')}} disabled={isLoading}>
              この内容で保存する
              <span style={{display:isLoading? 'inline':'none'}}><Spinner /></span>
            </button>
            <p className="help-block text-center">(「アイコン」のみ保存します)</p>
          </div>
        </section>

{/*
        <section id="group-cover" className="bg-section">
          <h2>カバー画像</h2>
          <ul className="list-normal">
            <li>スタジオページの上部に表示される画像です。未登録の場合は汎用的な画像が表示されます。</li>
            <li><span className="text-red">3Mbyte以下のJPEG, PNG形式の画像</span>が利用できます。</li>
            <li>1500px x 500pxの画像がおすすめです。</li>
          </ul>
          <div className="form-section">
            <DropzoneArea id="cover" type="image" maxSize={1024 * 1024 * 3} set={setCoverImage}/>
            <p className="invalid-feedback" style={{display:coverImageFb? 'none':'block'}}>
              ファイルを正しくセットしてください。
            </p>
            <p className="invalid-feedback" style={{display:coverImageFb2? 'none':'block'}}>
              ファイルのアップロードに失敗しました。
            </p>
          </div>
          <div id="cover-preview" style={{display:coverPreview===null? 'none':'block'}}>
              <h3>現在設定中のカバー画像</h3>
              <img src={coverPreview} alt="icon" />
          </div>
          <div className="form-section">
            <button type="button" className="btn btn-primary box-center" onClick={async ()=>{await ClickRegistBtn('cover')}} disabled={isLoading}>
              この内容で保存する
              <span style={{display:isLoading? 'inline':'none'}}><Spinner /></span>
            </button>
            <p className="help-block text-center">(「カバー画像」のみ保存します)</p>
          </div>
        </section>
*/}
        <section className="bg-section">
          <h2>SNS</h2>
          <ul className="list-normal">
            <li>スタジオに関連するSNSやWebサイトの情報を設定できます。</li>
            <li>スタジオページなどに表示されます。</li>
          </ul>
          <div id="group-twitter" className="form-section">
            <label className="form-label"><i className="icon icon-twitter" style={{backgroundColor:'#1DA1F2'}}></i> Twitter</label>
            <input type="text" id="twitter" className="form-control" placeholder="例：ankoingames" disabled={isLoading}/>
            <p className="invalid-feedback" style={{display:twitterFb? 'none':'block'}}>
              Twitterのアカウント名を正しく入力してください。
            </p>
          </div>
          <div id="group-youtube" className="form-section">
            <label className="form-label"><i className="icon icon-youtube" style={{backgroundColor:'#FF0000'}}></i> YouTube</label>
            <input type="text" id="youtube" className="form-control" placeholder="例：https://www.youtube.com/channel/example" disabled={isLoading}/>
            <p className="invalid-feedback" style={{display:youtubeFb? 'none':'block'}}>
              YouTubeのURLを正しく入力してください。
            </p>
          </div>
          <div id="group-discord" className="form-section">
            <label className="form-label"><i className="icon icon-discord" style={{backgroundColor:'#7289da'}}></i> Discord</label>
            <input type="text" id="discord" className="form-control" placeholder="例：https://discord.gg/example" disabled={isLoading}/>
            <p className="invalid-feedback" style={{display:discordFb? 'none':'block'}}>
              DiscordのURLを正しく入力してください。
            </p>
          </div>
          <div id="group-blog" className="form-section">
            <label className="form-label"><i className="icon icon-code" style={{backgroundColor:'#99aab5'}}></i> Webサイト <span className="text-muted text-small">（公式サイト、ブログなど）</span></label>
            <input type="text" id="blog" className="form-control" placeholder="例：https://blog.anko.in/" disabled={isLoading}/>
            <p className="invalid-feedback" style={{display:blogFb? 'none':'block'}}>
              URLを正しく入力してください。
            </p>
          </div>

          <div className="form-section">
            <button type="button" className="btn btn-primary box-center" onClick={async ()=>{await ClickRegistBtn('sns')}} disabled={isLoading}>
              この内容で保存する
              <span style={{display:isLoading? 'inline':'none'}}><Spinner /></span>
            </button>
            <p className="help-block text-center">(「SNS」のみ保存します)</p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Config;
