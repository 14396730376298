/**
 * InputGameID Component
 *
 **/

//-----------------------------------------------------------------------------
// モジュール
//-----------------------------------------------------------------------------
import { useState } from 'react';
import Spinner from './ui/Spinner';

// ライブラリ
import validation from '../libs/validation'
import API from '../libs/api'

// スタイル
import "../style/base.css"

//-----------------------------------------------------------------------------
// プロパティ
//-----------------------------------------------------------------------------
const FEEDBACK = {
  NONE: 0,
  VALID: 1,
  INVALID: 2,
  ERROR: 9
}

const optionsDefault = {
  id: 'gameid',
  className: '',
  placeholder: '例：pandapazzle'
}

/**
 * InputGameid
 *
 */
function InputGameID(props){
  const options = Object.assign(optionsDefault, props)

  // state
  const [className, setClassName] = useState(options.className)
  const [feedback, setFeedback] = useState(options.feedback? FEEDBACK.NONE:FEEDBACK.ERROR)
  const [loading, setLoading] = useState(false)
  delete options['className']
  delete options['feedback']

  //-------------------------------
  // 入力値のリアルタイムチェック
  //-------------------------------
  const checkGameID = (e)=>{
    const value = e.target.value
    let newClass = className.replace('form-invalid', '').replace('form-valid', '')
    let fb = FEEDBACK.NONE

    // メッセージ系をすべてOFF
    setFeedback(FEEDBACK.NONE)

    if( value === '' ){
      ;
    }
    else if( ! validation.validGameID(value) ){
      newClass += ' form-invalid'
      fb = FEEDBACK.ERROR
    }
    else{
      newClass += ' form-valid'
    }

    setClassName(newClass)
    setFeedback(fb)
  }

  //-------------------------------
  // ゲームID存在チェック
  //-------------------------------
  const existsCheck = async (e)=>{
    const gameid = e.target.value
    if(! validation.validGameID(gameid)){
      return;
    }

    // メッセージ系をすべてOFF
    setFeedback(FEEDBACK.NONE)

    // サーバに確認
    setLoading(true)
    const json = await API('/creator/game/id/exists', 'POST', {gameid, sessid:window.User.sessid})
    // エラー系
    if( ! json.head.status ){
      if(json.head.error.code === 400 && json.head.error.message.match(/^E10003/)){
        setFeedback(FEEDBACK.INVALID)
      }
      else{
        alert('ゲームIDの確認ができませんでした\nしばらく経ってから再度お試しください。')
      }
    }
    // すでに存在している or NGワード
    else if( json.data.exists ){
      setFeedback(FEEDBACK.INVALID)
    }
    // 利用可能
    else{
      setFeedback(FEEDBACK.VALID)
    }
    setLoading(false)
  }

  return(
    <>
      <input type="text" {...options} className={className} disabled={loading}
              onChange={checkGameID}
              onBlur={ async (e)=>{ await existsCheck(e)} }/>

      <p className="request-progress" style={{display:loading? 'block':'none'}}>
        <Spinner />...サーバに確認中
      </p>
      <p className="valid-feedback" style={{display:feedback === FEEDBACK.VALID? 'block':'none'}}>
        このゲームIDは利用できます
      </p>
      <p className="invalid-feedback" style={{display:feedback === FEEDBACK.INVALID? 'block':'none'}}>
        このゲームIDは利用できません<br />
        （すでに利用されているか、不適切な文字が含まれています）
      </p>
      <p className="invalid-feedback" style={{display:feedback === FEEDBACK.ERROR? 'block':'none'}}>
        ゲームIDを正しく入力してください
      </p>
    </>
  );
}

export default InputGameID;