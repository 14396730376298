import { useState } from 'react';
import Category from '../data/category.json';

import '../style/base.css'

function InputCategory(props){
  const optionsDefault = {
    id: "category",
    name: "category",
    className: "",
    first: {value:"0", label:"選択してください"},
    selected: 0
  }
  const options = Object.assign(optionsDefault, props);
  const [defaultItem, setDefaultItem] = useState(options.selected);

  return (
    <>
    <select id={options.id} name={options.name} className={options.className}
            value={defaultItem}
            onChange={ (e)=>{ setDefaultItem(e.target.value) } }>

      {/* 最初のプルダウン */}
      <CategoryOption key={options.first.value} label={options.first.label} value={options.first.value}/>

      {/* カテゴリ一覧 */}
      {Category.map(i => (
        <CategoryOption
            key={i.id}
            label={i.ja}
            value={i.id}
        />
      ))}
    </select>
    <p className="invalid-feedback" style={{display:props.feedback?  'none':'block'}}>
      カテゴリを選択してください
    </p>
    </>
  );
}

function CategoryOption(props){
  return (
    <option value={props.value}>{props.label}</option>
  );
}

export default InputCategory;